import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

export type IContract = {
    id: number;
    type: string;
    translationKey: string;
    category: string;
    isSchemaEmpty: boolean
};

export type IContractTypeData = {
    id: number;
    type: string;
    translationKey: string;
    contractTypes: IContract[];
};

export const contractCategoriesApi = createApi({
    reducerPath: "API/CONTRACT_CATEGORIES",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contractCategories'],
    endpoints(builder) {
        return {
            fetchContractCategories: builder.query<IContractTypeData[], void>({
                query() {

                    let endpointURL = `/cs-api/contractCategories/suggestion`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["contractCategories"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchContractCategoriesQuery } = contractCategoriesApi