// routes
import { PATH_CONTRACTABILITY } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

// const navConfig = [
  
//   {
//     subheader: 'CONTRACTABILITY',
//     items: [
//       {
//         title: 'CONTRACTABILITY',
//         path: PATH_CONTRACTABILITY.root,
//         icon: ICONS.user,
//         children: [
//           { title: 'Users', path: PATH_CONTRACTABILITY.users },
//           { title: 'Organizations', path: PATH_CONTRACTABILITY.organizations },
//           { title: 'Contracts', path: PATH_CONTRACTABILITY.contracts },
//         ],
//       },
//     ],
//   },
// ];

// { title: 'Organizations', path: PATH_ORGANIZATION.list, icon: ICONS.organizations },
// { title: 'Users', path: PATH_USER.list, icon: ICONS.user },
// { title: 'Groups', path: PATH_GROUP.list, icon: ICONS.group },
// { title: 'Contracts', path: PATH_CONTRACT.list, icon: ICONS.contracts },

const navConfig = [

  // { title: 'Users', path: PATH_USER.list, icon: ICONS.user },
  // { title: 'Groups', path: PATH_GROUP.list, icon: ICONS.group },
// { title: 'Contracts', path: PATH_CONTRACT.list, icon: ICONS.contracts },
  
  {
    subheader: 'CONTRACTABILITY',
    items: [
        // { title: 'Organizations', path: PATH_ORGANIZATION.list, icon: ICONS.organizations },
      
      {
        title: 'CONTRACTABILITY PUTHA',
        path: PATH_CONTRACTABILITY.root,
        icon: ICONS.user,
        children: [
          { title: 'Users', path: PATH_CONTRACTABILITY.users },
          { title: 'Organizations', path: PATH_CONTRACTABILITY.organizations },
          { title: 'Contracts', path: PATH_CONTRACTABILITY.contracts },
        ],
      },
    ],
  },
];

export default navConfig;
