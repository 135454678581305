import { Permission } from '../pages/contracts/contract-form/manage-access-popup/manage-access.types';

export const sortPermissions = (permissions: Permission[]): Permission[] => {
   const copyPermission = [...permissions]
   const sortedPermissionsList = copyPermission.sort((a, b) => {
      // First, compare by isGroup property
      if (a.isGroup !== b.isGroup) {
         return a.isGroup ? -1 : 1;
      } else {
         // If isGroup properties are the same, compare by name property
         return a.name.localeCompare(b.name);
      }
   });
   return sortedPermissionsList
}


type permissionData = {
   permissionId: number;
   permissionName: String;
};

type User = {
   userId: number;
   userName: String;
   permissionData: permissionData;
   email: string;
   directPermission?: boolean
};

type Group = {
   userGroupId: number;
   userGroupName: string;
   groupSize: number;
   permissionData: permissionData;
   directPermission?: boolean
};

export const createUserPermission = (user: User, ignoreDirectPermission?: boolean) => ({
   id: user.userId,
   name: user.userName,
   permissionId: user.permissionData.permissionId,
   permissionName: user.permissionData.permissionName,
   email: user.email,
   permission: user.permissionData,
   type: 'user',
   saveable: ignoreDirectPermission ? false : user.directPermission ? true : false
})
export const createGroupPermission = (group: Group, ignoreDirectPermission?: boolean) =>
({
   id: group.userGroupId,
   name: group.userGroupName,
   permissionId: group.permissionData.permissionId,
   permissionName: group.permissionData.permissionName,
   permission: group.permissionData,
   isGroup: true,
   type: 'group',
   saveable: ignoreDirectPermission ? false : group.directPermission ? true : false
})



export function replaceAccentedChars(str: string): string {
   const accentsMap: { [key: string]: string } = {
      'ö': 'o',
      'ä': 'a',
      'ü': 'u',
      'Ö': 'O',
      'Ä': 'A',
      'Ü': 'U',
      // Add more mappings as needed
   };
   const txt = str.replace(/[öäüÖÄÜ]/g, (match) => accentsMap[match] || match)
   return txt;
}

// export function matchAndReturn(text: string, pattern: string): void {
//    let mainStringWithoutSpaces = text.replace(/\s/g, '');
//    let subStringWithoutSpaces = pattern.replace(/\s/g, '');

//    let startIndex = mainStringWithoutSpaces.indexOf(subStringWithoutSpaces);
//    let endIndex = startIndex + subStringWithoutSpaces.length - 1;

//    console.log("startIndex:", startIndex); // Output: 2
//    console.log("endIndex:", endIndex);     // Output: 5
//    console.log(text.split("").splice(startIndex, endIndex + 1).join(""))

// }

function sanitizeText(text: any) {
   return text.replaceAll(/\s/g, '');
}

function normalizeString(str: string) {
   // Replace special characters with their normal equivalents
   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function matchAndReturn(text: string, pattern: string) {

   const patternSanitized = sanitizeText(pattern);

   let textCursor = 0;
   let patternCursor = 0;
   let startingIndex = null, endingIndex = null;
   let matchedText = null;

   while (textCursor < text.length) {
      // If the character is a space, continue
      if (text[textCursor] === ' ') {
         textCursor++;
         continue;
      }

      // Character of the text is equal to the character of the pattern
      if (normalizeString(text[textCursor]) === normalizeString(patternSanitized[patternCursor])) {
         // Set starting index
         if (startingIndex === null) {
            startingIndex = textCursor;
         }
         // Set ending index when end of the pattern reached
         if (patternCursor === patternSanitized.length - 1) {
            endingIndex = textCursor;
            break;
         }
         patternCursor++;
      } else {
         // Start over
         patternCursor = 0;
         startingIndex = endingIndex = null;
      }
      textCursor++;
   }


   // If the pattern found within text, extract it
   if (startingIndex !== null && endingIndex !== null) {
      matchedText = text.substring(startingIndex, endingIndex + 1); // + 1 for substring correction
   }

   return matchedText ? matchedText : null
}


let prevScrollTop = 0;

export const handleScrollTable = (e: any) => {
   const { scrollTop, clientHeight, scrollHeight } = e.target;

   // Check if the user is scrolling down
   if (scrollTop > prevScrollTop) {
      const callPosition = clientHeight + scrollTop + 200;

      if (callPosition > scrollHeight) {
         return true
      }
   }

   // Update the previous scroll position
   prevScrollTop = scrollTop;
};
