import { useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_USER } from '../../routes/paths';
// hooks

// _mock_

// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections

import useLocales from '../../hooks/useLocales';
import InboxForm from './inbox-form';



// ----------------------------------------------------------------------

export default function EmailInboxNew() {
  const { pathname } = useLocation();

  const isEdit = pathname.includes('edit');
  

  const { translate } = useLocales();

  return (
    <Page title={translate('inbox.new', 'Create an inbox')}>
      <Container maxWidth={false}>
        <HeaderBreadcrumbs
          heading={
            translate('inbox.new', 'Create an inbox')
          }
          links={[
            { name: translate('inbox.list', 'Inbox list'), href: PATH_USER.list },
            // {
            //   name: !isEdit
            //     ? translate('contract.contract', 'Contract')
            //     : translate('contract.new', 'New contract'),
            // },
          ]}
        />

       <InboxForm isEdit={isEdit} />
      </Container>
    </Page>
  );
}
