import { Button } from '@mui/material';
import CounterPartiesList from 'components/pagewise/counterparties/CounterPartiesList';
import AddIcon from '@mui/icons-material/Add';
import useLocales from 'hooks/useLocales';
import CustomPage from 'layouts/custom-page/custom-page';
import { useSearchParams } from 'react-router-dom';
import { useFetchPartiesListQuery } from 'redux/api/partyAPI';
import { useState } from 'react';
import PartyModel from 'components/models/PartyModel';

const CounterParties = () => {
    const { translate } = useLocales()
    const [searchParams] = useSearchParams();

    const page = searchParams.get('page');
    const rowsPerPage = searchParams.get('rowsPerPage');
    const pageNumber = page ? +page : 1
    const pageSize = rowsPerPage ? +rowsPerPage : 10

    const { data, isLoading, isFetching } = useFetchPartiesListQuery({ pageNumber: pageNumber, pageSize: pageSize, partyRoleId: 1 })

    const [open, setOpen] = useState(false)

    const breadcrumbsOptions = {
        heading: translate('user.counterParties', 'Counter party list'),
        links: [],
        action: <Button
            variant={'outlined'}
            onClick={() => setOpen(true)}
            startIcon={
                <AddIcon />
            }
            sx={{ mr: 1, position: "relative" }}
        >
            {translate('party.addCounterParty', 'Add counter party')}
        </Button>
    }
    return <CustomPage breadcrumbsOptions={breadcrumbsOptions} webpageTitle={translate('user.parties', 'Party List')}>
        <CounterPartiesList data={data} isLoading={isLoading || isFetching} />
        <PartyModel open={open} onClose={() => setOpen(false)} isEdit={false} partyType='Counter party' />
    </CustomPage>
}

export default CounterParties