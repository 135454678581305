import { ReactElement } from 'react';

// @mui
import {

  BreadcrumbsProps,
  Breadcrumbs as MUIBreadcrumbs,
} from '@mui/material';

// ----------------------------------------------------------------------

import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type TLink = {
  href?: string;
  name: string;
  icon?: ReactElement;
};

export interface Props extends BreadcrumbsProps {
  links: TLink[];
  activeLast?: boolean;
}



const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(4),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
function handleClickDelete(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a Delete.');
}
function handleOnClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a Item.');
}

const CustomizedBreadcrumbs: React.FC<Props> = ({ links }) => {

  const linkList = links?.map((e, i) => {
    if (links.length === i + 1) {
      return <StyledBreadcrumb key={i}
        label={e.name}
        icon={e.icon}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={handleClickDelete}
      />
    } else {
      return <StyledBreadcrumb key={i}
        component="a"
        href={e.href}
        label={e.name}
        icon={e.icon}
        onClick={handleOnClick}
      />
    }
  })
  return (
    <div>
      {/* <Breadcrumbs itemsAfterCollapse={2} itemsBeforeCollapse={0} maxItems={3} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {linkList}
      </Breadcrumbs> */}
    </div>
  );
}
export default CustomizedBreadcrumbs