import { TableCell } from '@mui/material'
import { IPartyListItem } from '../../../../@types/party'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { PATH_PARTIES } from 'routes/paths'

interface Props extends IPartyListItem {

}

const PartyRow = ({ name, number, uuid }: Props) => {
    const navigate = useNavigate()

    return (
        <Fragment>
            <TableCell
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`${PATH_PARTIES.edit.replace(':id', uuid?.toString())}`)}
                className="row_link"
            >
                {name}
            </TableCell>
            <TableCell>{number}</TableCell>
        </Fragment>
    )
}

export default PartyRow