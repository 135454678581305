import { Box, Card, CardContent, Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';

export default function UseLargerScreenMessage() {
    const { translate } = useLocales();
    return (
        <Card sx={{ m: 3, width: "75%" }} >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5" sx={{ p: 5 }}>
                        {translate('dashboard.useLargerScreen', 'For the best functionality and user experience, this site is currently only accessible on larger displays such as desktops, laptops or larger tablets. If you see this message, please switch to a larger screen. We apologize for any inconvenience and appreciate your understanding. Mobile support will come in a future update.')}
                    </Typography>
                </Box>
            </CardContent>
        </Card>

    )
}