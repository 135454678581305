import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { ContractFilterOptions } from 'interfaces/contract';
// ----------------------------------------------------------------------



type Filter = {
  open: boolean;
  filters: ContractFilterOptions | null;
  filterText: string

};

type Payload = {
  text: string;
  data: ContractFilterOptions
}

const initialState: Filter = {
  open: false,
  filters: null,
  filterText: ""
};

const slice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    // START LOADING
    setOpen(state) {
      state.open = true;
    },
    setClose(state) {
      state.open = false;
    },
    setReset(state) {
    

      state.filterText = "";
      state.filters = null;
    },
    setFilters(state, data) {
      


      // @ts-ignore

      // @ts-ignore
      state.filterText = data.payload.text;
      // @ts-ignore

      state.filters = data.payload.data;
    }


  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function showFilter() {
  dispatch(slice.actions.setOpen());

}

export function hideFilter() {
  dispatch(slice.actions.setClose());

}

export function resetFilters() {
  
  dispatch(slice.actions.setReset());

}

export function setFilters(payload: Payload) {

  dispatch(slice.actions.setFilters(payload));

}


