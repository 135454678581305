import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError, Method } from 'axios'
import { privateAxiosInstance } from 'utils/axios'

export const axiosBaseQuery = (): BaseQueryFn<
    {
        url: string
        method?: Method
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
        headers?: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
> => async ({ url, method, data, params, headers }) => {
    try {
        const result = await privateAxiosInstance({
            url: url,
            method,
            data,
            params,
            headers,
        })

        return { data: result.data }
    } catch (axiosError) {
        const err = axiosError as AxiosError
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            },
        }
    }
}