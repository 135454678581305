import useLocales from 'hooks/useLocales'
import { useMemo, useRef } from 'react'
import { Grid, Button, Card, CardContent, styled, Typography } from '@mui/material'
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput'
import FMSelectInput from 'components/reusable/formik-inputs/FMSelectInput'
import { orgTypeOptions, partiesInitialValues } from 'constants/initialValues/partiesInitialValues'
import { Form, Formik, FormikProps } from 'formik'
import { LoadingButton } from '@mui/lab'
import { IPartyItem } from '../../../@types/party'
import { partyValidationSchema } from 'constants/validations/partyValidationsSchemas'
import { useUpdatePartyByUUIDMutation } from 'redux/api/partyAPI'
import { useSnackbar } from 'notistack'
import useAPIError from 'hooks/useAPIError'
import AliasList from './rows/AliasList'
import { useNavigate } from 'react-router-dom';
import { PARTY_TYPE_ORGANIZATION } from 'pages/contracts/contract-types'

type Props = {
    data: IPartyItem | undefined
    isLoading: boolean
}

const EditParies = ({ data, isLoading }: Props) => {
    const { translate } = useLocales()
    const { handleError } = useAPIError()
    const { enqueueSnackbar } = useSnackbar();
    const [updatePartyByUUIDMutation, { isSuccess, isLoading: isLoadingSubmit }] = useUpdatePartyByUUIDMutation()

    // useMemo(() => error && handleError(error), [error, handleError])
    useMemo(() => isSuccess && enqueueSnackbar(translate("common.updated", "Updated"), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate])
    const navigate = useNavigate();

    const handleSubmit = async (formDataValues: any) => {

        const formData = { ...formDataValues }
        try {

            if (formData.partyRole === 2) {
                formData.aliases = formData.aliases?.map((e: any) => {
                    if (!e.uuid) {
                        return { ...e, id: null, uuid: null }
                    }
                    return e
                })
            } else {
                formData.aliases = []
            }

            formData.partyRole = {
                name: formData.partyRole === 1 ? "Counterparty" : "Own organization",
                id: formData.partyRole
            }

            formData.type = PARTY_TYPE_ORGANIZATION

            await updatePartyByUUIDMutation({ uuid: data?.uuid!, data: formData }).unwrap()
        } catch (error) {
            handleError(error)
        }
    }
    // Disable form submission on Enter key press
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
        }
    };
    const initialValues = partiesInitialValues(data)

    const content = (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={partyValidationSchema(translate)}
            enableReinitialize
        >
            {(formik: FormikProps<any>) => {
                const { values } = formik
                return <Form onKeyDown={handleKeyDown}>
                    <Grid container spacing={3}>
                        <Grid item container md={6} spacing={2} alignContent={"start"}>
                            <Grid item md={12}>
                                <FMTextInput name="name" label={translate("parties.name", "Name")} disabled={isLoading} />
                            </Grid>
                            <Grid item md={12}>
                                <FMTextInput name="number" label={translate("parties.organizationId", "Organization Id")} disabled={isLoading} />
                            </Grid>
                            <Grid item md={12}>
                                <FMSelectInput options={orgTypeOptions(translate)} value={values?.partyRole?.id} name="partyRole" label={translate("parties.organisationType", "Organisation type")} disabled={isLoading} />
                            </Grid>

                        </Grid>
                        {
                            values?.partyRole === 2 &&
                            <Grid item container md={6} spacing={2}>
                                <Grid item md={12}>
                                    <AliasList formik={formik} aliases={data ? values.aliases : []} />
                                </Grid>
                            </Grid>
                        }
                        <Grid item md={12} container justifyContent={"end"} justifyItems={"end"}>
                            <LoadingButton type='submit' loading={isLoadingSubmit} variant='contained'>{translate("common.save", "save")}</LoadingButton>
                            <Button
                                onClick={() =>
                                    navigate(-1)
                                }
                                variant="outlined"
                                sx={{ ml: 1 }}
                            >{translate('controls.cancel', 'Cancel')}
                            </Button>

                        </Grid>
                    </Grid>
                </Form>
            }}
        </Formik>


    )

    return <Card>
        <CardContent>
            <Grid container >
                <Grid item sm={12}>
                    {content}
                </Grid>
            </Grid>
        </CardContent>
    </Card>

}

export default EditParies
