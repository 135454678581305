import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { orgTypeOptions } from 'constants/initialValues/partiesInitialValues';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useLocales from 'hooks/useLocales';

type Props = {
    handleFilter: (val: 1 | 2 | undefined) => void
}

const PartyFilters = ({ handleFilter }: Props) => {
    const { translate } = useLocales()
    const [role, setRole] = useState<1 | 2 | undefined>()

    useEffect(() => {
        handleFilter(role)
    }, [handleFilter, role])

    const handleCheck = (e: any) => {
        if (role === e) {
            setRole(undefined)
        } else {
            setRole(e)
        }
    }

    return (
        <Box sx={{ display: "flex" }}>

            {orgTypeOptions(translate).map(e => <RootStyle onClick={() => handleCheck(e.value)} key={e.value} sx={{ display: "flex", py: 2, mx: 2 }}>{role === e.value ? <CheckCircleIcon color='success' /> : <CheckCircleOutlineIcon />}
                <Box sx={{ ml: 1 }}>{e.label}</Box>
            </RootStyle>)}
        </Box>
    )

}

export default PartyFilters

const RootStyle = styled('div')(({ theme }) => ({
    "&:hover": {
        cursor: "pointer"
    }
}));