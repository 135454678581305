// routes
import { PATH_USER, PATH_ORGANIZATION, PATH_GROUP, PATH_CONTRACT, PATH_FOLDER } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  group: getIcon('ic_user'),
  organizations: getIcon('ic_banking'),
  contracts: getIcon('file'),
};

const navConfig = [
  { title: 'Organizations', path: PATH_ORGANIZATION.list, icon: ICONS.organizations },
  { title: 'Users', path: PATH_USER.list, icon: ICONS.user },
  { title: 'Groups', path: PATH_GROUP.list, icon: ICONS.group },
  { title: 'Contracts', path: PATH_FOLDER.list, icon: ICONS.contracts },
];

export default navConfig;
