import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme?.customPalette?.autocomplete?.selectedBgColor ?? '',
            '&.Mui-focused': {
              backgroundColor: theme?.customPalette?.autocomplete?.focusBgColor ?? ''
            }
          }
        },
      },
    },
  };
}
