import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import { partyAPI } from "./partyAPI"

export type IContactFormPayload = {
    firstName: string,
    lastName: string,
    email: string,
    mobileNo: string,
    description: string
    party?: {
        uuid?: string;
    }
}
export type IContactPayload = {
    uuid: string,
    payload: IContactFormPayload
}

export const contactApi = createApi({
    reducerPath: "API/CONTACT",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contact'],
    endpoints(builder) {
        return {
            fetchContact: builder.query<any, { id: string }>({
                query({ id }) {
                    let endpointURL = `/cs-api/contents/${id}`

                    return {
                        url: endpointURL,
                    };
                },
                providesTags: ["contact"]
            }),
            addContact: builder.mutation<string, { payload: IContactFormPayload }>({
                query: ({ payload }) => ({
                    url: `/cs-api/contacts`,
                    data: payload,
                    method: 'POST',
                }),
                invalidatesTags: ["contact"],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(partyAPI.util.invalidateTags(["parties"]));
                },
            }),
            updateContact: builder.mutation<string, IContactPayload>({
                query: ({ uuid, payload }) => ({
                    url: `/cs-api/contacts/${uuid}`,
                    data: payload,
                    method: 'PUT',
                }),
                invalidatesTags: ["contact"],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(partyAPI.util.invalidateTags(["parties"]));
                },
            }),
        };
    }
})

export const { useFetchContactQuery, useUpdateContactMutation, useAddContactMutation } = contactApi