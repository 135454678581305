import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const privateAxiosInstance = axios.create({
  baseURL: HOST_API,
});


export default axiosInstance;
