import React from 'react';
import Page from 'components/Page';
import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';

type OrgLink = {
  name: string;
  href?: string;
};

type BreadcrumbsOptions = {
  heading?: string;
  links: OrgLink[];
  action?: React.ReactNode;
};

type Props = {
  webpageTitle: string;
  children: React.ReactNode;
  // isEdit?: boolean;
  // createHeading: string;
  // editHeading: string| '';
  // links: OrgLink[];
  breadcrumbsOptions: BreadcrumbsOptions;
};

export default function CustomPage(props: Props) {
  const { webpageTitle, children, breadcrumbsOptions } = props;
  return (
    <Page title={webpageTitle}>
      <Container style={{ maxWidth: 'none' }}>
        <HeaderBreadcrumbs {...breadcrumbsOptions} />
        {children}
      </Container>
    </Page>
  );
}
