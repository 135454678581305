import { TableCell, IconButton, Menu, MenuItem } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import Iconify from 'components/Iconify';
import { useNavigate } from 'react-router-dom';
import useLocales from 'hooks/useLocales';
import { PATH_INBOX } from 'routes/paths';
import Popup from 'layouts/popup/popup';
import { ManageAccessDialog } from 'pages/contracts/contract-form/manage-access-popup/manage-access-popup';
import { Permission } from 'pages/contracts/contract-form/manage-access-popup/manage-access.types';
type item = {
  id: string;
  name: string;
  email: string;
  handleDelete: (ids: string) => void;
  uuid: string;
};
export default function EmailInboxRow(item: item) {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { translate } = useLocales();
  const [openPopup, setOpenPopup] = useState(false);
  const [id, setId] = useState('');

  const deleteItem = (val: string) => {
    setId(String(val));
    setOpenPopup(true);
  };

  const deleteFunction = () => {
    if (item.handleDelete) {
      item.handleDelete(id);
    }
    setOpenPopup(false);
  };

  const [openManageAccess, setOpenManageAccess] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [userList, setUserList] = useState([]);
  const handleClose = () => {
    setOpenManageAccess(false);
    setOpen(false);
  };
  const handleSave = async (permissions: Permission[]) => {
    setPermissions(permissions);
  };

  const handleManageAccess = () => {
    setOpenManageAccess(true);
  };

  return (
    <Fragment>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>
        <div ref={inputEl}>
          <IconButton onClick={() => setOpen((val) => !val)}>
            <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
          </IconButton>
          <Menu open={open} anchorEl={inputEl.current} onClose={() => setOpen((val) => !val)}>
            <MenuItem onClick={() => navigate(`${PATH_INBOX.edit.replace(':id', item.uuid)}`)}>
              <Iconify icon={'eva:edit-fill'} sx={{ marginRight: 1, fontSize: '1.5rem' }} />
              {translate('common.edit', 'edit')}
            </MenuItem>
            <MenuItem onClick={handleManageAccess}>
              <Iconify
                icon={'material-symbols:group'}
                sx={{ marginRight: 1, fontSize: '1.5rem' }}
              />

              {translate('common.manageAccess', 'Manage access')}
            </MenuItem>
            <MenuItem onClick={() => deleteItem(item.uuid)}>
              <Iconify
                icon={'eva:trash-2-outline'}
                sx={{ marginRight: 1, fontSize: '1.5rem', color: 'error.main' }}
              />
              {translate('common.delete', 'delete')}
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
      <Popup
        open={openPopup}
        title={translate(
          'warnings.deleteInbox',
          'Are you sure want to delete the selected inboxes?'
        )}
        onClose={() => {
          setOpenPopup(false);
        }}
        deleteFunction={deleteFunction}
      />
      <ManageAccessDialog
        open={openManageAccess}
        onClose={handleClose}
        // @ts-ignore
        permissions={permissions}
        handleSave={handleSave}
        contractId={+item.id}
        contractName={translate(`contract.NoTitle`, 'Missing title')}
        userList={userList}
      />
    </Fragment>
  );
}
