import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//

import { ListItemIconStyle, ListItemTextStyle, ListItemStyle } from './style';

import { NavLink as RouterLink } from 'react-router-dom';
import useLocales from '../../../hooks/useLocales';
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  navConfigNew,
  ...other
}: NavSectionProps) {
  const [active, setActive] = useState<string>();
  const { translate } = useLocales();
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
           {/* @ts-ignore */}
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {/* @ts-ignore */}
          {navConfigNew.map((item) => (
            <ListItemStyle
              key={item.title}
              component={RouterLink}
              to={item.path}
              activeRoot={active === item.title}
              onClick={() => setActive(item.title)}
            >
              {item.icon && <ListItemIconStyle>{item.icon}</ListItemIconStyle>}
              <ListItemTextStyle
                disableTypography
                // @ts-ignore
                primary={translate(`common.${item.title}`, item.title)}
              />
            </ListItemStyle>
          ))}
        </List>
      ))}
    </Box>
  );
}
