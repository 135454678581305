import { createReducer, createSlice, Reducer } from "@reduxjs/toolkit";
type InitialState = {
    refreshFolderCount: number
}
const initialState: InitialState = {
    refreshFolderCount: 0
}

const slice = createSlice({
    name: "shared",
    initialState,
    reducers: {
        editFolderRefresh: (state) => {
            state.refreshFolderCount = state.refreshFolderCount + 1
        }
    }
})
export const { editFolderRefresh } = slice.actions
export const sharedStore: Reducer<InitialState> = slice.reducer
