import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

export type ITag = {
    id: number
    name: string
    organizationUuid: string
    userRole: string
}

export const tagApi = createApi({
    reducerPath: "API/TAG",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['tag'],
    endpoints(builder) {
        return {
            fetchTagsSuggestions: builder.query<ITag[], void>({
                query() {

                    let endpointURL = `/cs-api/tags/suggestion`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["tag"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchTagsSuggestionsQuery } = tagApi