import React from 'react';
import axiosInstance from '../utils/axios';
import  useAuth  from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import useLocales from './useLocales';
import jwtDecode from 'jwt-decode';
import { HOST_API ,COGNITO_API} from '../config';
import axios from 'axios';


export default function useRefresh() {
  const {logout} = useAuth()
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const refresh = async () => {
    try {
      const payload = new FormData();

      payload.append('grant_type', 'refresh_token');

      const token = window.localStorage.getItem('refreshToken');
      const refreshTokenExpiresIn = window.localStorage.getItem('refreshTokenExpiresIn') || '';

      if (!token) {
        throw new Error('Refresh token not found!');
      }

        payload.append('refresh_token', token);
        payload.append('refresh_token_expiry', refreshTokenExpiresIn);
        const { data } = await axiosInstance.post('/us-api/auth-detail/refreshToken', payload, {
          headers: {
            Authorization: 'Basic QVNQOlYxSTZyJWEpXjRqZkZHN2czNA==',
          },
        });
        return data;

    } catch (error) {

      const errorMessage = error?.response?.data?.error_description;

      if (errorMessage && errorMessage.includes('Refresh token expired')) {
        // LOGOUT IF REFRESH TOKEN EXPIRED
       
        enqueueSnackbar(translate('sessiontimeout', 'Logged out due to session expired'), {
          variant: 'error',
        });

      //  const accessToken = window.localStorage.getItem('accessToken');
      //  const refreshToken = window.localStorage.getItem('refreshToken');

        // let userForwardURI = "/"
        // if (accessToken) {
        //   const tokenData: any = jwtDecode(accessToken);
        //   const logoutResponse = await axios.get(`${HOST_API}/us-api/auth-detail/logout?username=${tokenData['cognito:username']}&token=${refreshToken}`);
        //   userForwardURI = logoutResponse?.data?.userForwardURI;
        // }
        window.location.href = HOST_API;
       
         logout()
      }
    }
  };

  return refresh;
}
