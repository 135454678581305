import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import useLocales from 'hooks/useLocales';
import Iconify from 'components/Iconify';
type Props = {
  permission: Permission;
  makeAViewer: (permissionId: number) => void;
  makeAnEditor: (permissionId: number) => void;
  removePermission: (permissionId: number) => void;
  show: boolean;
};

type Permission = {
  name: string;
  avatarUrl: string;
  isGroup?: boolean;
  email: string;
  permission: permissionData;
  id: number;
  permissionName: string;
  type: string;
};

type permissionData = {
  permissionId: number;
  permissionName: String;
};

export default function ManageAccessDropDown(props: Props) {
  const { translate } = useLocales();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { permission, makeAViewer, makeAnEditor, removePermission, show } = props;

  return (
    <div>
      {/* <span style={{ color: '#1f1f1f', fontSize: '1rem' }}>{permission?.permissionName}</span> */}
      {show && (<IconButton onClick={handleClick}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>)}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem
          onClick={() => {
            makeAViewer(permission.id);
            handleClose();
          }}
        >
          {translate('manage-access-popup.viewer', 'Viewer')}
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => {
            makeAnEditor(permission.id);
            handleClose();
          }}
        >
          {translate('manage-access-popup.editor', 'Editor')}
        </MenuItem>
        {show && <hr />} */}
        {show && (
          <MenuItem onClick={() => removePermission(permission.id)}>
            {translate('manage-access-popup.remove-access', 'Remove Access')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
