import React from 'react';
import { TableRow, TableCell, TableHead, TableSortLabel, Checkbox, checkboxClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Header = {
  text: string;
  sortable?: boolean;
};
type Props = {
  headers: Header[];
  allowMultiSelect: boolean;
  direction: 'asc' | 'desc';
  sortHandler: (text: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  selectAllHandler: () => void;
};
export default function CustomTableHeaders(props: Props) {
  const theme = useTheme();
  const { headers, allowMultiSelect, direction, sortHandler, orderBy, selectAllHandler } = props;

  let tableHeadCss = {
    backgroundColor: theme.customPalette?.tableHeadColor ?? "",
    backgroundImage: "unset",
    color: theme.customPalette?.tableHeadFontColor ?? "",
    boxShadow: theme.customPalette?.tableHeadBoxShadow ?? "unset !important"
  }

  return (
    <TableHead>
      <TableRow>
        {allowMultiSelect && (
          <TableCell sx={tableHeadCss}>
            <Checkbox sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: theme.customPalette?.tableHeadFontColor ?? '',
              }
            }} onClick={selectAllHandler} />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell key={header.text} sx={tableHeadCss}>
            {header.text}
            {header.sortable && (
              <TableSortLabel
                active={orderBy === header.text}
                direction={direction}
                onClick={() => sortHandler(header.text, direction)}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
