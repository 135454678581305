import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const logo = (
    <Box sx={{ padding: theme?.customPalette?.spinnerLogoBoxPadding ?? "1.7rem 0" }}>
      <img
        src={theme?.customPalette?.spinnerLogo ?? 'https://tagd.ai/wp-content/uploads/2022/10/Logo-Tagd.png'}
        // src={'/t10.jpeg.png'}
        style={{ maxHeight: theme?.customPalette?.spinnerLogoHeight ?? '40px' }}
        alt="tagd-logo"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
