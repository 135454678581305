import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import PartiesList from 'components/pagewise/parties/PartiesList';
import PartyFilters from 'components/pagewise/parties/PartyFilters';
import useLocales from 'hooks/useLocales';
import CustomPage from 'layouts/custom-page/custom-page';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetchPartiesListQuery } from 'redux/api/partyAPI';

type Props = {}

const Parties = () => {
    const { translate } = useLocales()
    const [searchParams] = useSearchParams();

    const page = searchParams.get('page');
    const rowsPerPage = searchParams.get('rowsPerPage');
    const pageNumber = page ? +page : 1
    const pageSize = rowsPerPage ? +rowsPerPage : 10

    const [partyRoleId, setPartyRoleId] = useState<1 | 2 | undefined>()

    const { data, isLoading, isFetching } = useFetchPartiesListQuery({ pageNumber: pageNumber, pageSize: pageSize, partyRoleId: partyRoleId })

    const handleFilter = (val: 1 | 2 | undefined) => {
        setPartyRoleId(val)
    }

    const breadcrumbsOptions = {
        heading: translate('user.parties', 'Party List'),
        links: [],
        action: <PartyFilters handleFilter={handleFilter} />
    }
    return <CustomPage breadcrumbsOptions={breadcrumbsOptions} webpageTitle={translate('user.parties', 'Party List')}>
        <PartiesList data={data} isLoading={isLoading || isFetching} />
    </CustomPage>
}

export default Parties