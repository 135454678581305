import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

type StaticsDataType = {
    data: any
};

const initialState: StaticsDataType = {
    data: ""
};

const slice = createSlice({
    name: 'staticsStorage',
    initialState,
    reducers: {
        setStaticsData(state, data) {
            // @ts-ignore
            state.data = data.payload;
        }
    },
});

// Reducer
export default slice.reducer;

export function setStaticsData(payload: any) {
    dispatch(slice.actions.setStaticsData(payload));
}