import { OutlinedInputProps, TextField } from '@mui/material';
import { } from 'formik';
import React from 'react';

type Props = {
    name: string
    onChange?: (eve: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (eve: React.KeyboardEvent<HTMLInputElement>) => void
    label: string
    value?: string
    disabled?: boolean
    size?: "medium" | "small"
};

const InputControlText = ({ label, name, disabled, onChange, onKeyDown, value, size }: Props) => {
    console.log();
    return (

        <TextField
            size={size ? size : "medium"}
            name={name}
            value={value || ""}
            onChange={onChange}
            label={label}
            disabled={disabled}
            fullWidth
            onKeyDown={onKeyDown}
        />

    );
};

export default InputControlText;
