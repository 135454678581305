export enum ITermTypes {
    UNTIL_FURTHER_NOTICE = "UNTIL_FURTHER_NOTICE",
    AUTO_RENEWAL = "AUTO_RENEWAL",
    FIXED = "FIXED",
    OTHER = "OTHER"
}
export enum INoticePeriodTypes {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR"
}
export enum IContractStatus {
    WAITING_FOR_REVIEWED = 'WAITING_FOR_REVIEWED',
    NEW_REVIEWED_SUGGESTED = 'NEW_REVIEWED_SUGGESTED',
    NEW_REVIEWED_REQUIRED = 'NEW_REVIEWED_REQUIRED',
    TERMINATED = 'TERMINATED',
    DELETED = 'DELETED',
    REVIEWED = 'REVIEWED',
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    IMPORTED = 'IMPORTED',
    PROCESSING = 'PROCESSING'
}
interface IUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNo: string;
}

export interface IContractOwner {
    id: number;
    uuid: string;
    userInfo: IUserInfo;
}

export interface ITags {
    id?: number,
    name: string
}

export interface IContractDetail {
    id: number;
    title: string;
    jsonData: any;
    effectiveDate: string | null;
    endDate: string | null;
    noticePeriod: string | null;
    noticePeriodType: INoticePeriodTypes | null;
    tags: ITags[];
    description: string | null;
    lastReviewedDate: string | null;
    storageLocationUrl: string | null;
    storageLocation: string | null;
    untilFurtherNotice: boolean;
    emailReminderDate: string | null;
    terms: ITermTypes | null;
    terminationDeadlineDate: string | null;
}

export interface IFile {
    id: number;
    fileName: string;
    fileUuid: string;
    link: string;
    type: string;
    status: string;
    createdBy: number;
    lastModifiedAt: string;
    createdAt: string;
    updatedBy: string;
    ocrStatus: string;
    jsonFileName: string;
    preSignedUrl: string;
    aiSuggestionStatus: any
    reprocessedAt: any
}

export interface IPermission {
    permissionName: string;
}

export interface IFolderPath {
    id: number;
    uuid: string;
    name: string;
    parentUuid: string | null;
    level: number;
}

export interface IFolder {
    id: number;
    uuid: string;
    name: string;
    folderPath: IFolderPath[];
    folderType: string;
}

export interface IAiSuggestionContractType {
    contractTypeId: string;
    contractType: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
}

export interface IAiSuggestionParty {
    partyName: string;
    personFirstName: string;
    personLastName: string;
    partyIdentificationNumber: string;
    partyAddress: string;
    partyEmail: string;
    partyPhone: string;
    partyType: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
    //   ownParty: boolean;
}

export interface IAiSuggestionDate {
    date: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
}

export interface IAiSuggestionNoticePeriod {
    noticePeriod: string;
    noticePeriodType: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
}

export interface IAiSuggestionContractTerm {
    term: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
}

export interface IAiSuggestionContactPerson {
    partyName: string;
    personFirstName: string;
    personLastName: string;
    personEmail: string;
    personPhone: string;
    lineText: string;
    paragraph: string | null;
    pageNumber: string;
}

export interface IAiSuggestionResponse {
    contractTypes: IAiSuggestionContractType[];
    parties: IAiSuggestionParty[];
    effectiveDates: IAiSuggestionDate[];
    endDates: IAiSuggestionDate[];
    noticePeriod: IAiSuggestionNoticePeriod[];
    contractTerms: IAiSuggestionContractTerm[];
    contactPerson: IAiSuggestionContactPerson[];
    signatoryPerson: any;
    status: string | null;
}

export interface IContractType {
    id: number
    type: string
    translationKey: string
}


export interface IContractContent {
    id: number;
    contractOwner: IContractOwner;
    contractDetail: IContractDetail;
    files: IFile[];
    contractParties: any[];
    contractType: IContractType | null;
    status: string;
    contractStatus: IContractStatus;
    createdBy: string;
    lastModifiedAt: string;
    permission: IPermission;
    folder: IFolder;
    ocrContractTypes: any;
    ocrDates: any;
    ocrNoticePeriods: any;
    ocrCounterParties: any;
    fileOCRStatus: any;
    ocrTags: any;
    ocrOrganizations: any;
    recordType: string;
    notificationToDecesion: string;
    ocrContractTerms: any;
    expired: boolean;
    aiSuggestionResponse: IAiSuggestionResponse;
    suggestedParties: any[];
    suggestedContacts: any[];
    suggestedContractOwners: any[];
    suggestedContractPeriod: any;
    contractPeriods: any[];
}

export type IContractPagination = {
    pageNumber: number
    pageSize: number
    totalPages: number
    totalRecords: number
};
export type IContentListResponse = {
    content: IContractContent[]
    pagination: IContractPagination
}