import { number } from "yup";
import { IPartyItem, IPartyItemValues } from "../../@types/party";
import { IContact } from "../../@types/contact";

export const partiesInitialValues = (data: IPartyItem | undefined, partyModalType?: number) => {
    const fields: Partial<IPartyItemValues> = {
        name: "",
        number: "",
        partyRole: partyModalType ? partyModalType : 1,
        aliases: []
    }
    if (data) {
        fields.name = data.name
        fields.number = data.number
        fields.partyRole = data.partyRole.id
        fields.aliases = data.aliases
    }

    return fields
}
export const partyColumns = (translate: any) => {
    const headers = [
        {
            text: translate('user.organizationId', 'Organization id'),
            sortable: false,
        }, {
            text: translate('user.Name', 'Name'),
            sortable: false,
        },
        {
            text: translate('user.organizationNumber', 'Organization Number'),
            sortable: false,
        },
        {
            text: translate('user.contracts', 'Contracts'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}
export const aliasesPartyColumns = (translate: any) => {
    const headers = [
        {
            text: translate('user.Name', 'Name'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}

export const orgTypeOptions = (translate: any) => ([
    {
        label: translate("editParties.ownOrganization", "Own organization"),
        value: 2
    }, {
        label: translate("editParties.counterParty", "Counter party"),
        value: 1
    },

])

export const contactInitialValues = (data: IContact | undefined) => {

    const fields = {
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        description: '',
    }

    if (data) {
        fields.firstName = data.firstName
        fields.lastName = data.lastName
        fields.email = data.email ? data.email : ""
        fields.mobileNo = data.mobileNo ? data.mobileNo : ''
        fields.description = data.description ? data.description : ""
    }


    return fields
}