// @ts-nocheck
import React, { useEffect } from 'react';
import useRefresh from './useRefresh';
import { privateAxiosInstance } from '../utils/axios';
import { AxiosRequestConfig } from 'axios';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import useLocales from './useLocales';
import jwtDecode from 'jwt-decode';

export default function useAxiosPrivate() {
  const refresh = useRefresh();
  const { translate } = useLocales();

  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');

  useEffect(() => {
    const requestIntercept = privateAxiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (config && !config.headers.Authorization && accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = privateAxiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {

        const prevRequest = error?.config;
        // ACCESS TOKEN EXPIRED, RE-TRY WITH NEW ACCESS TOKEN

        if (
          error?.response?.status === 401 &&
          error?.response?.data?.error === 'invalid_token' &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const tokens = await refresh();

          window.localStorage.setItem('accessToken', tokens.id_token);
          window.localStorage.setItem('refreshToken', tokens.refresh_token);

          prevRequest.headers.Authorization = `Bearer ${tokens.id_token}`;
          return privateAxiosInstance(prevRequest);
        } else if (
          error?.response?.status === 401 &&
          error?.response?.data?.error === 'invalid_token' &&
          prevRequest?.sent
        ) {
          // window.localStorage.removeItem('accessToken');
          // window.localStorage.removeItem('refreshToken');
          enqueueSnackbar(translate('sessiontimeout', 'Logged out due to session expired'), {
            variant: 'error',
          });

          let userForwardURI = '/';
          console.log('auto logout started...');
          if (accessToken) {
            console.log('auto logout if condition started...');
            const tokenData: any = jwtDecode(accessToken);
            const logoutResponse = await privateAxiosInstance.get(
              `/us-api/auth-detail/logout?username=${tokenData['cognito:username']}&token=${refreshToken}`
            );
            console.log('auto logout if condition ended...', logoutResponse);
            userForwardURI = logoutResponse?.data?.userForwardURI;
          }

          console.log('auto logout method called...');

          logout();
          console.log('redirecting to login page...');
          window.location.href = userForwardURI;
          // window.location.href = '/';
        } else {
          return Promise.reject((error.response && error) || 'Something went wrong');
        }
      }
    );

    return () => {
      privateAxiosInstance.interceptors.response.eject(responseIntercept);
      privateAxiosInstance.interceptors.response.eject(requestIntercept);
    };
  }, [refresh]);

  return privateAxiosInstance;
}
