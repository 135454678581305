import { IconButton, TableCell } from '@mui/material'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_COUNTER_PARTIES } from '../../../../routes/paths'
import Iconify from 'components/Iconify'
import { Menu } from '@mui/material'
import { MenuItem } from '@mui/material'
import useLocales from 'hooks/useLocales'
import Popup from 'layouts/popup/popup'
import { useDeletePartyByUUIDMutation } from 'redux/api/partyAPI'
import { useSnackbar } from 'notistack'
import useAPIError from 'hooks/useAPIError'
import { IContact } from '../../../../@types/contact'
import ContactPersonModel from 'components/models/ContactPersonModel'

interface Props extends IContact {

}

const ContactRow = (props: Props) => {
    const { firstName, lastName, email, mobileNo } = props
    const { translate } = useLocales()
    const inputEl = useRef(null);
    const [open, setOpen] = useState<boolean>(false)
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    const onClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
        setMenuOpen(false)
    }

    return (
        <Fragment>
            <TableCell className="row_link" >{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{mobileNo}</TableCell>
            <TableCell width={50}>
                {/* <Iconify icon={'eva:edit-fill'} sx={{ marginRight: 1, fontSize: '1.5rem', cursor: "pointer" }} onClick={handleOpen} /> */}
                <div ref={inputEl}>
                    <IconButton onClick={() => setMenuOpen((val) => !val)}>
                        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
                    </IconButton>
                    <Menu open={menuOpen} anchorEl={inputEl.current} onClose={() => setMenuOpen((val) => !val)}>

                        <MenuItem onClick={handleOpen} >
                            <Iconify icon={'eva:edit-fill'} sx={{ marginRight: 1, fontSize: '1.5rem' }} />

                            {translate('common.edit', 'edit')}
                        </MenuItem>

                        {/* <MenuItem disabled={!isEditor} onClick={() => deleteItem(item.id)}>
                            <Iconify
                                icon={'eva:trash-2-outline'}
                                sx={{ marginRight: 1, fontSize: '1.5rem', color: 'error.main' }}
                            />

                            {translate('common.delete', 'delete')}
                        </MenuItem> */}
                    </Menu>
                </div>
            </TableCell>
            <ContactPersonModel open={open} onClose={onClose} isEdit={true} contact={props} />
        </Fragment>
    )
}

export default ContactRow