import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, IconButton, Popover, styled, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import React, { useState } from 'react'
import useLocales from 'hooks/useLocales';
import { useContract } from 'pages/contracts/contract-form/contract-provider';

type Props = {
  data: any;
  handleChange(path: string, value: any): void;
  path: string;
};

const WandItem: React.FC<Props> = (props) => {
  const contract: any = useContract();

  const { data, handleChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { translate } = useLocales();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return data ? (
    <div>
      <IconButton onClick={handleClick} sx={{ color: '#2065D1' }}>
        <AutoFixHighIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography
          sx={{
            color: '#637381',
            textTransform: 'uppercase',
            fontWeight: 700,
            padding: '1rem 1rem',
          }}
        >
          {translate('suggestions.fromSuggestions', 'From suggestion')}
        </Typography>

        <PopBody onMouseOver={() => contract.handleHighlight(data)}>
          <p>{data}</p>
        </PopBody>
      </Popover>
    </div>
  ) : null;
};

const PopBody = styled('div')(() => ({
  background: 'rgba(145, 158, 171, 0.16)',
  padding: '1rem',
  maxWidth: '31rem',
  marginBottom: '2px',
  color: '#637381',
  textAlign: 'justify',
  '& span': {
    fontWeight: 'bold',
  },
  '& cursor': 'pointer',
}));

export const JFWandItem = withJsonFormsControlProps(WandItem);

const Label: React.FC<any> = (data) => {
  const { translate } = useLocales()
  const translationKey = data?.uischema?.options?.translationKey

  return <Box>{translationKey ? translate(translationKey, data.data) : data.data}</Box>;
};

export const JFLabel = withJsonFormsControlProps(Label);

const Paragraph: React.FC<Props> = ({ data }) => {
  return <ParaBody>{/* § {data} */}</ParaBody>;
};

export const JFParagraph = withJsonFormsControlProps(Paragraph);


const ParaBody = styled("div")(() => ({
}))
