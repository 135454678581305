import CustomTable from 'layouts/custom-table/custom-table';
import PartyRow from './rows/PartyRow';
import useLocales from 'hooks/useLocales';
import { partyColumns } from 'constants/initialValues/partiesInitialValues';
import { IPartyListDetails } from '../../../@types/party';


type Props = {
    data: IPartyListDetails | undefined
    isLoading: boolean
}

const PartiesList = ({ data, isLoading }: Props) => {
    const { translate } = useLocales()


    return <CustomTable
        headers={partyColumns(translate)}
        rowsData={data ? data.content : []}
        SingleRow={PartyRow}
        allowMultiSelect={false}
        isLoading={isLoading}
        totalRecords={data?.pagination?.totalRecords}
    />
}

export default PartiesList