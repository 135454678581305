import React from "react";
import Joyride, { Step, TooltipRenderProps, CallBackProps } from "react-joyride";
import { Typography, Button, Box } from "@mui/material";
import { useState, useEffect, useRef } from 'react';
import useLocales from 'hooks/useLocales';




//Overide joyride default back button and next button
const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
}: TooltipRenderProps) => {
    return (
        <Box
            {...tooltipProps}
            sx={{
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: 2,
                boxShadow: 3,
                p: 2,
                maxWidth: 300,
            }}
        >
            <Typography variant="body1" gutterBottom>
                {step.content}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                {index > 0 && (
                    <Button {...backProps} variant="outlined">
                        Back
                    </Button>
                )}
                <Box ml="auto">
                    {continuous ? (
                        <Button {...primaryProps} variant="contained" color="primary">
                            Next
                        </Button>
                    ) : (
                        <Button {...primaryProps} variant="contained" color="success">
                            Finish
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    console.log("data", data)
    /* 
        if (type === "step:after" && index === 0) {
            // If "Search Contracts" step is completed, navigate to the page
            navigate("/search-contract");
        }
    
        if (status === "finished" || status === "skipped") {
            // Stop the tour when it's finished or skipped
            setRunTour(false);
            setStepIndex(0); // Reset to the first step for future tours
        } else if (action === "next") {
            // Move to the next step
            setStepIndex((prevIndex) => prevIndex + 1);
        } else if (action === "prev") {
            // Move to the previous step
            setStepIndex((prevIndex) => prevIndex - 1);
        } */
};

const ProductTour: React.FC<{ triggerTour: boolean }> = ({ triggerTour }) => {

    const { translate } = useLocales();
    const [runTour, setRun] = useState(true);




    useEffect(() => {
        setRun(triggerTour);
        console.log("runTour", runTour)
    }, [triggerTour]);

    const steps: Step[] = [
        {
            target: ".navbar-search-contract",
            content: (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {translate('navbar.searchContract', 'Search contract')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Use this section to search for contracts efficiently.
                    </Typography>
                    <Button variant="contained" color="primary">
                        Got it
                    </Button>
                </Box>
            ),
            placement: "bottom",
            disableBeacon: true,
        },
        {
            target: ".navbar-new-contract",
            content: (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {translate('navbar.newContract', 'New contract')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Click here to create a new contract.
                    </Typography>
                    <Button variant="outlined" color="secondary">
                        Learn More
                    </Button>

                </Box>
            ),
            placement: "top",
            disableBeacon: false,
        },
        {
            target: ".upload-file-browse",
            content: (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {translate('upload.viewSelectFile', 'Select file')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {translate(
                            'upload.viewSelectFileMessage',
                            'Select file to attach to contract update'
                        )}
                    </Typography>

                </Box>
            ),
            placement: "top",
            disableBeacon: true
        },
        {
            target: ".btn-contract-upload",
            content: (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        You are done..!
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Click here to navigate back to the dashboard
                    </Typography>

                </Box>
            ),
            placement: "top",
            disableBeacon: true
        },
        {
            target: ".navbar-profile",
            content: (
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {translate('navbar.profile', 'Profile')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Click here to edit profile.
                    </Typography>

                </Box>
            ),
            placement: "top",
            disableBeacon: true
        },

    ];

    return (
        <Joyride
            steps={steps}
            run={runTour}
            continuous
            showProgress
            showSkipButton
            tooltipComponent={CustomTooltip} // Use custom tooltip
            styles={{
                options: {
                    zIndex: 10000,
                    overlayColor: "rgba(0, 0, 0, 0.5)",
                },
            }}
            callback={handleJoyrideCallback} // Handle tour events
            disableOverlayClose={true} // Allow clicks on target elements
            spotlightClicks={true} // Enable clicking on spotlighted elements
        />
    );
};

export default ProductTour;