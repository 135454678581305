import { createAsyncThunk } from "@reduxjs/toolkit";
import { contentApi, IFetchContentListPayload } from "../../api/contentApi";
import { resetContents } from "./contentSlice";

interface IFetchContentListPayloadWithExtra extends IFetchContentListPayload {
    RESET?: boolean
    RESET_CACHE?: boolean

}

export const fetchContents = createAsyncThunk(
    "content/fetchContents",
    async (payload: IFetchContentListPayloadWithExtra, { dispatch, getState }) => {
        try {

            payload.RESET_CACHE && dispatch(contentApi.util.resetApiState())
            payload.RESET && dispatch(resetContents())
            const { data } = await dispatch(contentApi.endpoints.fetchContentList.initiate(payload))



            return data
        } catch (error) {
            console.log(error);
            return error
        }
    })