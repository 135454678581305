import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import CustomPage from 'layouts/custom-page/custom-page';
import CustomTable from 'layouts/custom-table/custom-table';
import Iconify from 'components/Iconify';
import { PATH_INBOX } from 'routes/paths';
import useLocales from 'hooks/useLocales';
import { useSnackbar } from 'notistack';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import EmailInboxRow from './email-inbox-row';

type Item = {
  id: string;
  name: string;
  email: string;
  uuid: string;
};

export default function EmailInboxList() {
  const { translate } = useLocales();


  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>();

  const headers = [
    {
      text: translate('userName', 'Name'),
      sortable: false,
    },
    {
      text: translate('userEmail', 'Email'),
      sortable: false,
    },
    {
      text: '',
      sortable: false,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const privateAxiosInstance = useAxiosPrivate();

  const page = searchParams.get('page');
  const rowsPerPage = searchParams.get('rowsPerPage');
  const loadData = async (fetchingURL: string) => {
    const { data } = await privateAxiosInstance.get(fetchingURL);
    return data;
  };
  const fetchAgain = async () => {
    try {
      let pageValue = page && page === '0' ? '1' : page;
      let rowsPerPageValue = rowsPerPage ? parseInt(rowsPerPage) : 10;
      const data = await loadData(
        `/cs-api/inboxes?pageSize=${rowsPerPageValue || 10}&pageNumber=${pageValue || 1}`
      );
      setRows(data.content);
      setTotalRecords(data.pagination.totalRecords);
    } catch (error) {
      enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), {
        variant: 'error',
      });
    }
  };

  const breadcrumbsOptions = {
    action: (
      <Button
        variant="contained"
        component={RouterLink}
        to={PATH_INBOX.newInbox}
        startIcon={<Iconify icon={'eva:plus-fill'} />}

      >
        {translate('inboxNew', 'Create inbox')}
      </Button>
    ),
    heading: translate('inboxList', 'Inbox list'),
    links: [
      { name: 'Inbox', href: '/inbox/list', icon: <HomeIcon fontSize="small" /> },
      { name: 'Items list ' },
    ],
  };

  const [filterName, setFilterName] = useState('');
  const onFilterName = (text: string) => {
    setFilterName(text);
  };

  const handleDelete = async (ids: string) => {
    try {
      await privateAxiosInstance.delete('/cs-api/inboxes/' + ids);
      if (rows) {
        const idsList = ids.split(',');


        const filters = rows.filter((row: Item) =>
          idsList.includes(String(row.uuid)) ? false : true
        );

        if (filters.length) {
          setRows(filters);
        } else {
          setRows([]);
        }
      }
    } catch (error) {
      enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fetchAgain();
  }, [rowsPerPage, page]);
  const props = {
    webpageTitle: 'Inbox list',
    breadcrumbsOptions,
  };
  return <CustomPage {...props} >
    <CustomTable
      headers={headers}
      filterName={filterName}
      onFilterName={onFilterName}
      SingleRow={EmailInboxRow}
      allowMultiSelect={false}
      rowsData={rows}
      fetchAgain={fetchAgain}
      totalRecords={totalRecords}
      handleDelete={handleDelete}
    />
  </CustomPage>;
}
