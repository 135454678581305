import { Theme } from '@mui/material/styles';


// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'white' },
          style: {
            borderRadius: "25px",
            border: "1.5px solid #575756",
            textTransform: "none",
            fontWeight: "700",
            boxShadow: "4px 4px 0px #E5E4E6",
            color: "#575756",
            // backgroundColor:"#E0C6E1"
          },
        },
        {
          props: { variant: 'green' },
          style: {
            borderRadius: "25px",
            border: "1.5px solid #00A364",
            textTransform: "none",
            fontWeight: "700",
            boxShadow: "4px 4px 0px #E5E4E6",
            color: "#00A364",
            // backgroundColor:"#E0C6E1"
          },
        }
      ],
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.customPalette?.defaultButton?.hoverBC ?? ""
          },
          textTransform: theme.customPalette?.defaultButton?.textTransform ?? "none",
          color: theme.customPalette?.defaultButton?.color ?? "",
          fontSize: theme.customPalette?.defaultButton?.fontSize ?? ""
          // borderRadius: "25px",
          // border: "1.5px solid #575756",
          // fontWeight: "700",
          // boxShadow: "4px 4px 0px #E5E4E6",
          // backgroundColor:"#E0C6E1"
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          fontSize: theme.customPalette?.containedButtonFontSize ?? "",
          borderRadius: "25px",
          border: theme.customPalette?.containedPrimary?.border ?? "1.5px solid #575756",
          textTransform: theme.customPalette?.containedButtonTextTransform ?? "none",
          fontWeight: theme.customPalette?.containedButtonFontWeight ?? "700",
          boxShadow: theme.customPalette?.containedButtonBoxShadow ?? "4px 4px 0px #E5E4E6",
          color: theme.customPalette?.containedPrimary?.color ?? "#523D53",
          backgroundColor: theme.customPalette?.containedPrimary?.backgroundColor ?? "#E0C6E1",
          '&:hover': {
            backgroundColor: theme.customPalette?.containedPrimary?.backgroundColorHover ?? '#c5abc6',
          },
          // boxShadow: theme.customShadows.primary,
          // borderRadius: "25px",
          // border: "1.5px solid #575756",
          // textTransform: "none",
          // fontWeight: "700",
          // boxShadow: "4px 4px 0px #E5E4E6",
          // color:"#523D53",
          // backgroundColor:"#E0C6E1"
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          fontSize: theme.customPalette?.containedErrorButtonFontSize ?? "",
          borderRadius: "25px",
          border: "1.5px solid #575756",
          textTransform: theme.customPalette?.containedErrorButtonTextTransform ?? "none",
          fontWeight: theme.customPalette?.containedErrorButtonFontWeight ?? "700",
          boxShadow: theme.customPalette?.containedErrorBoxShadow ?? "4px 4px 0px #E5E4E6",
          color: "#523D53",
          backgroundColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
          },

          // boxShadow: theme.palette.error.main,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },

        outlinedPrimary: {
          fontSize: theme.customPalette?.outlinedButtonFontSize ?? "",
          borderRadius: "25px",
          border: "1.5px solid #575756",
          textTransform: theme.customPalette?.containedButtonTextTransform ?? "none",
          fontWeight: theme.customPalette?.outlinedButtonFontWeight ?? "700",
          boxShadow: theme.customPalette?.outlineButtonBoxShadow ?? "4px 4px 0px #E5E4E6",
          color: "#523D53",
          '&:hover': {
            backgroundColor: '#FFF',
            border: "1.5px solid #575756",
          },
        },
        outlinedSuccess: {
          border: "1.5px solid #00A364",
          borderRadius: "25px",
          color: "#575756"
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
