import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

type IUserGroups = { createdAt: string, createdBy: string, default: boolean, id: number, members: number, userGroupName: string }

export const groupAPI = createApi({
    reducerPath: "API/GROUP",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['userGroups'],
    endpoints(builder) {
        return {
            fetchUserGroups: builder.query<IUserGroups[], void>({
                query() {

                    let endpointURL = `/us-api/user-group`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["userGroups"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchUserGroupsQuery } = groupAPI