// react error boundary component
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, } from '@mui/material';
// components
import Page from './components/Page';
import { MotionContainer, varBounce } from './components/animate';
// assets

import PageErrorIllustration from './page_error.png';


interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Page title="The page could not load" sx={{ height: 1 }}>
        <RootStyle>
          <Container component={MotionContainer}>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  The page could not load
                </Typography>
              </m.div>
              <Typography sx={{ color: 'text.secondary' }}>
                The server has stumbled. Page could not be loaded. Please try again later or contact support if the error persist.
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center" }} >
                {/* <PageErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}
                <img src={PageErrorIllustration} alt="404" width="90%" />
              </Box>


              <Button size="large" variant='contained' onClick={() => window.location.reload()} sx={{ mr: 2 }}  >
                Retry
              </Button>
              <Button to="/" size="large" variant="outlined" component={RouterLink}>
                Go to Home
              </Button>
            </Box>
          </Container>
        </RootStyle>
      </Page>
    }
    return this.props.children;
  }
}