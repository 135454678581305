import useLocales from 'hooks/useLocales'
import { useMemo } from 'react'
import { Box, Button, Card, CardContent, Grid, styled } from '@mui/material'
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput'
import { Form, Formik, FormikProps } from 'formik'
import { LoadingButton } from '@mui/lab'
import { IPartyItem } from '../../../@types/party'
import { partyValidationSchema } from 'constants/validations/partyValidationsSchemas'
import { useUpdatePartyByUUIDMutation } from 'redux/api/partyAPI'
import { useSnackbar } from 'notistack'
import useAPIError from 'hooks/useAPIError'
import { counterPartiesInitialValues } from 'constants/initialValues/counterPartiesInitialValues'
import { Typography } from '@mui/material'
import { PARTY_TYPE_ORGANIZATION } from 'pages/contracts/contract-types'

type Props = {
    data: IPartyItem | undefined
    isLoading: boolean
}

const EditCounterParies = ({ data, isLoading }: Props) => {
    const { translate } = useLocales()
    const { handleError } = useAPIError()
    const { enqueueSnackbar } = useSnackbar();
    const [updatePartyByUUIDMutation, { isSuccess, isLoading: isLoadingSubmit }] = useUpdatePartyByUUIDMutation()

    // useMemo(() => error && handleError(error), [error, handleError])
    useMemo(() => isSuccess && enqueueSnackbar(translate("common.updated", "Updated"), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate])

    const handleSubmit = async (formDataValues: any) => {

        const formData = { ...formDataValues }
        try {

            formData.partyRole = {
                name: "Counterparty",
                id: formData.partyRole,

            }
            formData.type = PARTY_TYPE_ORGANIZATION

            await updatePartyByUUIDMutation({ uuid: data?.uuid!, data: formData }).unwrap()
        } catch (error) {
            handleError(error)
        }
    }

    const initialValues = counterPartiesInitialValues(data)

    const container = (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={partyValidationSchema(translate)}
            enableReinitialize
        >
            {(formik: FormikProps<any>) => {
                return <Form >
                    <Grid container spacing={3}>
                        <Grid item container md={12} spacing={2} alignContent={"start"}>
                            <Grid item md={12}>
                                <FMTextInput name="name" label={translate("parties.name", "Name")} disabled={isLoading} />
                            </Grid>
                            <Grid item md={12}>
                                <FMTextInput name="number" label={translate("parties.organizationId", "Organization Id")} disabled={isLoading} />
                            </Grid>
                            <Grid item md={12} style={{ display: "flex", justifyContent: "end" }}>
                                <LoadingButton type='submit' loading={isLoadingSubmit} variant='contained'>{translate("common.submit", "Submit")}</LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            }}
        </Formik>
    )

    return <Card>
        <CardContent>
            <Grid container >
                <Grid item sm={12}>
                    <HeaderBetween>
                        <Typography variant='subtitle1'>{translate('parties.details', 'Details')}</Typography>
                    </HeaderBetween>
                </Grid>
                <Grid item sm={12}>
                    {container}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

export default EditCounterParies

const HeaderBetween = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    height: "30px",
    ".MuiTypography-root": {
        margin: 0,
        padding: 0,
        color: theme.palette.grey[600]
    }
}))