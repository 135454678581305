import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

type ILocation = { locationName: string, organizationUuid: string, id: number };

export const storageAPI = createApi({
    reducerPath: "API/STORAGE",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['storageLocations'],
    endpoints(builder) {
        return {
            fetchStorageLocations: builder.query<ILocation[], void>({
                query() {

                    let endpointURL = `/cs-api/storageLocations/suggestion`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["storageLocations"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

        }
    }
})

export const { useFetchStorageLocationsQuery } = storageAPI