import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "redux/axiosBaseQuery"
import { IContractFolderItem } from "../../@types/contract";
import { ICreateFolder } from '../../../src/@types/inbox';

export const folderAPI = createApi({
  reducerPath: 'API/FolderSlice',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Folder'],
  endpoints(builder) {
    return {
      fetchFolder: builder.query<IContractFolderItem, string>({
        query(id) {
          return {
            url: `/cs-api/folders/${id}`,
          };
        },
        transformResponse: (response: any) => response.content,
        providesTags: (result, error, id) => [{ type: 'Folder', id }],
      }),
      createFolder: builder.mutation<
        Record<any, any>,
        {
          payload: ICreateFolder;
        }
      >({
        query({ payload }) {
          const endpointURL = '/cs-api/folders';
          return {
            url: endpointURL,
            method: 'POST',
            data: payload,
          };
        },
      }),
    };
  },
});

export const { useFetchFolderQuery, useCreateFolderMutation } = folderAPI