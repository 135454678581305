import { IconButton, TableCell, Typography } from '@mui/material'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_CONTRACT, PATH_COUNTER_PARTIES } from '../../../../routes/paths'
import Iconify from 'components/Iconify'
import { Menu } from '@mui/material'
import { MenuItem } from '@mui/material'
import useLocales from 'hooks/useLocales'
import Popup from 'layouts/popup/popup'
import { useDeletePartyByUUIDMutation } from 'redux/api/partyAPI'
import { useSnackbar } from 'notistack'
import useAPIError from 'hooks/useAPIError'
import { IContact } from '../../../../@types/contact'
import DescriptionIcon from '@mui/icons-material/Description';
import { IContractContent } from '../../../../@types/contractResponse'
import moment from 'moment'
import ContractStatus from 'components/contract-status'
import { PARTY_TYPE_PRIVATE } from 'pages/contracts/contract-types'

interface Props extends IContractContent {

}

const ContractRow = (props: Props) => {
    const navigate = useNavigate()
    const { translate } = useLocales()

    function analazingFailed(reprocessedAt: string) {
        const reprocessedAtDate = moment(reprocessedAt, "YYYY-MM-DD HH:mm:ss");
        const newDate = reprocessedAtDate.add(1, 'hours');
        const now = moment();

        return newDate.isBefore(now);
    };

    const handleClick = () => {
        if (props.contractStatus === 'DRAFT' || props.contractStatus === 'IMPORTED') {
            navigate(PATH_CONTRACT.edit.replace(':id', `${props.id}`));
        } else {
            navigate(`${PATH_CONTRACT.viewContract.replace(':id', `${props.id}`)}`);
        }
    }

    const title = props.contractDetail?.title ? props.contractDetail.title : translate(`contract.NoTitle`, 'Missing title')

    let counterParty = "";
    if (props.contractParties && props.contractParties.length) {
        let names = props.contractParties.map((party) => {
            if (party?.party?.type === PARTY_TYPE_PRIVATE) {
                return `${party.party.name}`;
            } else {
                if (party?.party?.partyRole?.id !== 2) {
                    return party?.party?.name;
                }
            }
        })
            .filter((e) => e)
            .join(', ');

        counterParty = names;
    }
    return (
        <Fragment>
            <TableCell
                style={{ cursor: 'pointer' }}
                className="row_link"
                onClick={handleClick} >
                <Typography
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '.9rem',
                        overflowWrap: 'anywhere',
                        maxWidth: '400px',
                    }}
                    component="div"
                >
                    <DescriptionIcon
                        sx={{ width: 36, height: 36, mr: 2 }}
                        width={36}
                        height={36}
                        color="info"
                    />
                    {title}
                </Typography>
            </TableCell>

            <TableCell>
                {props.contractType?.translationKey ? translate(`contractTypes.${props.contractType?.translationKey}`, props.contractType?.type) : ''}
            </TableCell>

            <TableCell>
                {props.contractOwner?.userInfo?.firstName} {props.contractOwner?.userInfo?.lastName}
            </TableCell>

            <TableCell style={{ maxWidth: '170px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >
                {counterParty}
            </TableCell>

            {props.expired ? (
                <TableCell sx={{ color: 'red' }}>{props.contractDetail?.endDate}</TableCell>
            ) : (
                <TableCell>{props.contractDetail?.endDate}</TableCell>
            )}

            <TableCell>
                {props.contractDetail?.noticePeriod} &nbsp;
                {props.contractDetail?.noticePeriodType ? translate(`contract.${props.contractDetail?.noticePeriodType}`, props.contractDetail?.noticePeriodType) : ''}
            </TableCell>

            <TableCell>
                {props.files?.[0]?.aiSuggestionStatus === 'PROCESSING' ?
                    analazingFailed(props.files?.[0]?.reprocessedAt) ?
                        <ContractStatus contractStatus={'ANALYSIS_FAILED'} /> :
                        <ContractStatus contractStatus={'PROCESSING'} /> :
                    (<ContractStatus contractStatus={props.contractStatus} />)
                }
            </TableCell>
        </Fragment>
    )
}

export default ContractRow