import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { fetchContents } from './contentAction'
import { IContractFolderItem } from '../../../@types/contract'

const initialState = {
   loading: false,
   isLastPage: false,
   totalRecords: 0,
   contentsRows: [] as IContractFolderItem[],
}

type IInitialState = typeof initialState

const store = createSlice({
   name: "contentsRows",
   initialState,
   reducers: {
      resetContents: (state) => {
         state.contentsRows = []
         state.totalRecords = 0
         state.isLastPage = false
      },
      removeContentRow: (state, action: PayloadAction<{ id: number }[]>) => {
         state.contentsRows = state.contentsRows.filter(e => !action.payload.some(m => m.id === e.id))
      },
      updateContentRowItem: (state, action: PayloadAction<IContractFolderItem>) => {
         state.contentsRows = state.contentsRows.map(e => {
            if (e.id === action.payload.id) {
               return action.payload
            }
            return e
         })
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchContents.pending, (state) => {
            state.loading = true
         })
         .addCase(fetchContents.fulfilled, (state, action: Record<string, any>) => {
            console.log("actionactionaction", action.payload.content);
            const payload = { ...action.payload }
            const mappedData: IContractFolderItem[] = []

            payload.content.map((item: any) => {
               if (item.contractOwner && item.contractOwner.userInfo) {
                  const { firstName, lastName } = item.contractOwner.userInfo;
                  mappedData.push({ ...item, contractOwnerName: firstName + ' ' + lastName })
               } else {
                  mappedData.push({ ...item, contractOwnerName: "" })
               }
            });

            state.isLastPage = action.payload.content.length !== action.payload.pagination.pageSize

            state.contentsRows = state.contentsRows.concat(mappedData)
            state.totalRecords = action.payload.pagination.totalRecords
            state.loading = false
         })
         .addCase(fetchContents.rejected, (state) => {
            state.loading = false
         })
   }
})
export const { resetContents, removeContentRow, updateContentRowItem } = store.actions
export const contentStore: Reducer<IInitialState> = store.reducer
