import { createSlice, Reducer, PayloadAction } from "@reduxjs/toolkit";
import { Permission } from "pages/contracts/contract-form/manage-access-popup/manage-access.types";

interface InitialState {
    loading: boolean,
    permissions: Permission[]
}

const initialState: InitialState = {
    loading: false,
    permissions: []
}

const store = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        setPermissions(state, action: PayloadAction<Permission[]>) {
            state.permissions = action.payload
        }
    }
})

export const { setPermissions } = store.actions
export const permissionsStore: Reducer<InitialState> = store.reducer;