import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Field, FormikProps, Form, FormikHelpers } from 'formik';
import { Grid, Button, CardContent, Card, Typography } from '@mui/material';
import { TextField, Switch } from 'formik-mui';
import * as Yup from 'yup';
import useLocales from 'hooks/useLocales';
import { useSnackbar } from 'notistack';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_INBOX } from 'routes/paths';
import { WhoHasAccess } from 'pages/contracts/contract-form/who-has-access/who-has-access';

interface Props {
  name: string;
  isEmailEnabled: boolean;
}

type ComponentProps = {
  isEdit: boolean;
};

export default function InboxForm(props: ComponentProps) {
  const { isEdit } = props;
  const privateAxiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const handleSubmit = async (e: Props, { resetForm }: FormikHelpers<Props>) => {
    try {
      if (isEdit) {
        await privateAxiosInstance.put('/cs-api/inboxes/' + id, {
          name: e.name,
          emailEnabled: e.isEmailEnabled,
        });

        enqueueSnackbar(translate('inbox.updated', 'Inbox successfully updated.'));
        navigate(PATH_INBOX.list);
      } else {
        await privateAxiosInstance.post('/cs-api/inboxes', {
          name: e.name,
          emailEnabled: e.isEmailEnabled,
        });
        enqueueSnackbar(translate('inbox.created', 'Inbox successfully created.'));
        navigate(PATH_INBOX.list);
        // resetForm();
      }
    } catch (error) {
      enqueueSnackbar('User creation failed.', { variant: 'error' });
    }
  };
  const [initialValues, setInitialValues] = useState({
    name: '',
    isEmailEnabled: true,
  } as Props);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    isEmailEnabled: Yup.boolean().required('Required'),
  });

  const { id } = useParams();
  useEffect(() => {
    if (isEdit && id) {
      const fetchData = async () => {
        try {
          const { data } = await privateAxiosInstance.get('/cs-api/inboxes/' + id);

          setInitialValues({
            name: data.content.name,
            isEmailEnabled: data.content.emailEnabled,
          });
        } catch (error) {
          enqueueSnackbar('Fetching inbox failed', { variant: 'error' });
        }
      };
      fetchData();
    }
  }, [isEdit, id]);

  const [openAccessManageModal, setOpenAccessManageModal] = useState(false);
  const name = 'hard coded name';
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik: FormikProps<Props>) => (
        <Form>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <Field
                    name="name"
                    label={translate('inbox.emailName', 'Name')}
                    component={TextField}
                    fullWidth
                    required
                  />

                  <Field component={Switch} type="checkbox" name="isEmailEnabled" />
                  <label>{translate('inbox.enable', 'Enable sending emails to this inbox')}</label>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item sx={{ flex: 1 }}>
                      {/* <CardHeader
                            title={translate('contractAccess.whoHasAccess', 'Who has access')}
                          /> */}
                      <Typography variant="h6" gutterBottom>
                        {translate('contractAccess.whoHasAccess', 'Who has access')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: '2rem', mt: 3, mr: 3, top: '-3px' }}
                        onClick={() => setOpenAccessManageModal(true)}
                      >
                        {translate('common.manageAccess', 'Manage access')}
                      </Button>
                    </Grid>
                  </Grid>

                  <WhoHasAccess
                    openAccessManageModal={openAccessManageModal}
                    contractId={Number(0)}
                    handleOnOpenManageModal={() => setOpenAccessManageModal((prev) => !prev)}
                    contractName={name}
                    isManageAccessButton={false}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    {!isEdit
                      ? translate('common.create', 'Create')
                      : translate('common.save', 'Save')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
