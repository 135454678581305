import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Grid } from '@mui/material';
import useLocales from 'hooks/useLocales';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import ProductTour from './ProductTour';


interface Props {
    openTourDialog: boolean;
    onClose: () => void;
}

export default function ChangeContractStatusDropdown(props: Props) {
    const { openTourDialog, onClose } = props;
    const { translate } = useLocales();

    const [internalOpen, setInternalOpen] = useState(openTourDialog);
    const [triggerTour, setTriggerTour] = useState(false);


    useEffect(() => {
        setInternalOpen(openTourDialog);
    }, [openTourDialog]);


    const onClosePop = () => {
        setInternalOpen(false);
        onClose();
    };

    const startProductTour = () => {
        setTriggerTour(true)
        setInternalOpen(false)
        localStorage.setItem('productTour', JSON.stringify({ status: 'started' }));
    }
    const pausProductTour = () => {
        setInternalOpen(false);
        localStorage.setItem('productTour', JSON.stringify({ status: 'paused' }));
    }

    return (
        <>
            <ProductTour triggerTour={triggerTour} />
            <Dialog open={internalOpen} onClose={onClosePop} maxWidth="sm" fullWidth={true}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DialogTitle sx={{ paddingBottom: '1rem' }}>
                        Welcome to the Product Tour! 🎉
                    </DialogTitle>
                    <Box>
                        <IconButton onClick={onClosePop} sx={{ padding: '0.5rem' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <DialogContent sx={{ pt: 0 }}>
                    <Grid container item spacing={3} md={12}>
                        <Grid item xs={12} sm={12} md={12}>
                            <LoadingButton
                                variant="white"
                                style={{ marginRight: '1rem', textTransform: 'none', marginLeft: 'auto' }}
                                onClick={pausProductTour}
                            >
                                {translate('productTour.pausTour', 'Paus product tour')}
                            </LoadingButton>
                            <LoadingButton
                                color="primary"
                                variant="contained"
                                onClick={startProductTour}
                            >
                                {translate('productTour.startTour', 'Start product tour')}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

        </>
    );
}
