import { ReactNode } from 'react';
import isString from 'lodash/isString';
// @mui
import { Box, Typography, Link } from '@mui/material';
//
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';
import FolderBreadcrumb from '../pages/contracts/move-folder/folder-breadcrumb';
import { useNavigate } from 'react-router-dom';
import { IContractFolderItem } from '../@types/contract'

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  leftAction?: ReactNode;
  action?: ReactNode;
  heading?: string;
  headingColor?: string;
  subHeading?: string;
  moreLink?: string | string[];
  folder?: IContractFolderItem | undefined;
  displayFolderBreadCrumbs?: boolean;
}

export default function HeaderBreadcrumbs({
  links,
  leftAction,
  action,
  heading,
  headingColor,
  subHeading,
  moreLink = '' || [],
  sx,
  folder,
  displayFolderBreadCrumbs,
  ...other
}: Props) {

  const navigate = useNavigate();

  const handleBackToFolder = async () => {
    navigate(`/folders`);

  }

  const getFolders = (uuid: string) => {
    console.log("getFolders", uuid)
    navigate(`/folders/${uuid}`);
  }


  return (
    <Box sx={{ mb: 3, ...sx }} >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: (leftAction) ? 0 : 1 }}>
          {heading && <Typography variant="h4" gutterBottom sx={{ color: headingColor ? headingColor : "" }}>
            {heading}
          </Typography>}

          {displayFolderBreadCrumbs && <FolderBreadcrumb size='lg' goIn={true} selectFolder={folder} handleBackToFolder={handleBackToFolder} getFolders={getFolders} />}
          <div style={{ fontSize: '14px' }}>{subHeading}</div>
          <Breadcrumbs links={links} {...other} />
        </Box>
        {leftAction && <Box sx={{ flexGrow: 1, mt: -1 }}>{leftAction}</Box>}
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
