import React, { useState } from 'react';
import {
  Stack,
  Typography,
  StackProps,
  IconButton,
  Tooltip,
  Checkbox,
  checkboxClasses,
} from '@mui/material';
import Iconify from 'components/Iconify';
import Popup from 'layouts/popup/popup';
import useLocales from 'hooks/useLocales';
import { useTheme } from '@mui/material/styles';

interface Props extends StackProps {
  numSelected: string[];
  rowsCount: number;
  handleDelete?: (ids: string) => void;
  handleRestore?: (ids: string) => void;
  handleSelection: () => void;
  isDisableDelete?: boolean;
  isDisableAccess?: boolean;
  enableRestore?: boolean;
  deleteConfirmationMsg?: string;
  openCloseModal?: (value: boolean) => void;
  setSelectedIds?: (value: any) => void;
}

export default function SelectedRowsHeader({
  numSelected,
  rowsCount,
  handleDelete,
  handleRestore,
  handleSelection,
  isDisableDelete,
  isDisableAccess,
  enableRestore,
  deleteConfirmationMsg,
  openCloseModal,
  setSelectedIds,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const handleDeleteRows = () => {
    if (handleDelete) {
      handleDelete(numSelected.join(','));
    }
  };

  const handleRestoreRows = () => {
    if (handleRestore) {
      handleRestore(numSelected.join(','));
    }
  };

  const handleSelect = () => {
    handleSelection();
  };
  const [openPopup, setOpenPopup] = useState(false);

  const deleteItem = () => {
    setOpenPopup(true);
  };

  const handleAccess = () => {
    if (openCloseModal) {
      openCloseModal(true);
      if (setSelectedIds) setSelectedIds(numSelected);
    }
  };

  return (
    <Stack
      direction="row"
      // justifyContent="center"
      alignItems="center"
      sx={{
        px: 2,
        borderRadius: 1,
        height: 58,
        bgcolor: theme.customPalette?.tableHeadColor ?? 'primary.lighter',
      }}
    >
      <Checkbox
        checked={numSelected.length === rowsCount}
        indeterminate={numSelected.length !== rowsCount}
        onClick={handleSelect}
        sx={{
          [`&, &.${checkboxClasses.checked}`]: {
            color: theme.customPalette?.tableHeadFontColor ?? '',
          },
          [`&, &.${checkboxClasses.indeterminate}`]: {
            color: theme.customPalette?.tableHeadFontColor ?? '',
          },
        }}
      />
      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: theme.customPalette?.tableHeadFontColor ?? 'primary.main',
        }}
      >
        {numSelected.length} {translate('common.selected', 'Selected')}
      </Typography>
      {!!openCloseModal && !isDisableAccess && (
        <Tooltip title={translate('common.quickActions', 'Quick actions')}>
          <IconButton
            sx={{ color: theme.customPalette?.tableHeadFontColor ?? 'primary.main' }}
            onClick={() => handleAccess()}
          >
            <Iconify icon={'eva:person-add-fill'} />
          </IconButton>
        </Tooltip>
      )}
      {!isDisableDelete && (
        <Tooltip title={translate('common.delete', 'Delete')}>
          <IconButton
            sx={{ color: theme.customPalette?.tableHeadFontColor ?? 'primary.main' }}
            onClick={() => deleteItem()}
          >
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
        </Tooltip>
      )}
      {enableRestore && (
        <Tooltip title={translate('common.restore', 'Restore')}>
          <IconButton
            sx={{ color: theme.customPalette?.tableHeadFontColor ?? 'primary.main' }}
            onClick={() => handleRestoreRows()}
          >
            <Iconify icon={'mingcute:back-2-fill'} />
          </IconButton>
        </Tooltip>
      )}
      <Popup
        open={openPopup}
        title={
          deleteConfirmationMsg
            ? deleteConfirmationMsg
            : translate('warnings.delete', 'Are you sure want to delete?')
        }
        onClose={() => {
          setOpenPopup(false);
        }}
        deleteFunction={handleDeleteRows}
      />
    </Stack>
  );
}
