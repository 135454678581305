import { Box, Button, Card, CardContent, Grid, styled, Typography } from '@mui/material'
import { partyContactColumns } from 'constants/initialValues/counterPartiesInitialValues'
import useLocales from 'hooks/useLocales'
import CustomTable from 'layouts/custom-table/custom-table'
import AddIcon from '@mui/icons-material/Add';
import ContactRow from './rows/ContactRow'
import { IContact } from '../../../@types/contact'
import { useState } from 'react';
import ContactPersonModel from 'components/models/ContactPersonModel';

type Props = {
    contacts?: IContact[]
}

const PartiesContactList = ({ contacts }: Props) => {
    const { translate } = useLocales()
    const [open, setOpen] = useState<boolean>(false)

    return (
        <Card>
            <CardContent>
                <Grid container >
                    <Grid item sm={12}>
                        <HeaderBetween>
                            <Typography variant='subtitle1'>{translate('parties.contacts', 'Contacts')}</Typography>
                            <Box display="flex" justifyContent="flex-end" alignItems={'center'}>
                                <Button variant="outlined" size="small" onClick={() => setOpen((val) => !val)} >
                                    <AddIcon />
                                    {translate('party.createContact', 'Create contact')}
                                </Button>
                            </Box>
                        </HeaderBetween>

                        <CustomTable
                            headers={partyContactColumns(translate)}
                            rowsData={contacts?.length ? contacts : []}
                            SingleRow={ContactRow}
                            allowMultiSelect={false}
                            withoutCard={true}
                            paginate={false}
                        />
                    </Grid>
                    <ContactPersonModel open={open} onClose={() => setOpen(false)} isEdit={false} />
                </Grid>
            </CardContent>
        </Card>
    )
}

export default PartiesContactList


const HeaderBetween = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    height: "30px",
    ".MuiTypography-root": {
        margin: 0,
        padding: 0,
        color: theme.palette.grey[600]
    }
}))