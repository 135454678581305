import React from 'react';
import { Typography, Grid, Stack, IconButton, Box, Dialog, DialogContent } from '@mui/material';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useLocales from 'hooks/useLocales';
import { LoadingButton } from '@mui/lab';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput';
import CheckMobileNoValidity from 'utils/checkMobileNoValidity';
import { IContact } from '../../@types/contact';
import { contactInitialValues } from 'constants/initialValues/partiesInitialValues';
import { IContactFormPayload, useAddContactMutation, useUpdateContactMutation } from 'redux/api/contactApi';
import useAPIError from 'hooks/useAPIError';
import { useParams } from 'react-router';
import { contactPersonValidationSchema } from 'constants/validations/contactPersonValidations';

type Props = {
  onClose: () => void;
  open: boolean;
  contact?: IContact;
  isEdit: boolean;
};

function ContactPersonModel(props: Props) {
  const { id } = useParams()
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useAPIError()

  const { onClose, open, isEdit, contact } = props;

  const [updateContact, { isLoading: isLoadingUpdateContact }] = useUpdateContactMutation()
  const [addContact, { isLoading: isLoadingAddContact }] = useAddContactMutation()

  const isLoading = isLoadingUpdateContact || isLoadingAddContact

  const handleSubmit = async (e: IContactFormPayload, { setSubmitting }: FormikHelpers<IContactFormPayload>) => {
    try {
      const payload = { ...e, party: { uuid: id } }
      if (isEdit) {
        await updateContact({ payload, uuid: contact!.uuid }).unwrap()
        enqueueSnackbar(translate('contactPerson.successUpdated', `Contact person successfully updated.`));
      } else {
        await addContact({ payload }).unwrap()
        enqueueSnackbar(translate('contactPerson.success', `Contact person successfully created.`));
      }
      onClose();

    } catch (error) {
      handleError(error)
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Formik
          initialValues={contactInitialValues(contact)}
          onSubmit={handleSubmit}
          validationSchema={contactPersonValidationSchema(translate)}
          enableReinitialize
        >
          {(formik: FormikProps<IContactFormPayload>) => {
            const { isSubmitting, values, setFieldValue, isValid } = formik;
            return (
              <Form>
                <Grid container item md={12} spacing={2} sx={{ py: 2.5, px: 3 }}>
                  <Grid item md={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >

                      <Typography variant="subtitle1">
                        {!isEdit ? translate('contract.createContact', 'Create contact') : translate('contract.editContact', 'Edit contact')}
                      </Typography>

                      <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <FMTextInput name="firstName" label={translate('user.firstName', 'First Name')} required />
                  </Grid>
                  <Grid item md={12}>
                    <FMTextInput name="lastName" label={translate('user.lastName', 'Last Name')} required />
                  </Grid>
                  <Grid item md={12}>
                    <FMTextInput name="email" label={translate('user.email', 'Email')} />
                  </Grid>
                  <Grid item md={12}>
                    <MuiPhoneNumber
                      name="mobileNo"
                      defaultCountry={'se'}
                      onChange={(value) => {
                        setFieldValue('mobileNo', value);
                        CheckMobileNoValidity(value);
                      }}
                      fullWidth
                      variant="outlined"
                      value={values.mobileNo}
                      autoFormat={false}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting || isLoading}
                        disabled={!isValid}
                      >
                        {translate('common.save', 'Save')}
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default ContactPersonModel;
