import { IPartyAlias } from "../../@types/party";

export type ContractPartyItem = {
  id: number;
  mainId: number;
  name: string;
  number: string;
  partyPerson: string;
  partyType: string;
  type: string;
  contactPerson: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    uuid: string;
    organizationUuid: string;
    mobileNo: string;
  };
};

export type ContractTerm = {
  term: string;
  lineText: string;
  termGeometry: {
    boundingBox: {
      width: number;
      height: number;
      left: number;
      top: number;
    };
    polygon: {
      x: number;
      y: number;
    }[];
  };
  pageNumber: number;
  coordinates: {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
  };
};

export type AiContractTerm = {
  term: string;
  lineText: string;
};

export type TermsSuggestionsType = {
  term: string;
  lineText: string;
};

export interface Ocrorg {
  organizationNumber: string;
  organizationEUVATNumber: string;
  lineText: string;
  organizationGeometry: {
    boundingBox: {
      width: number;
      height: number;
      left: number;
      top: number;
    };
    polygon: {
      x: number;
      y: number;
    }[];
  };
  pageNumber: number;
  coordinates: {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
  };
}

export type PartyRecord = {
  name: string;
  type: string;
  number: string;
  id: string;
  partyType: string;
  partyRole: {
    type: string;
  };
  contactPerson?: any;
  partyPerson?: any;
  mainId?: number;
  btnStatus: boolean;
  organizationUuid: string;
  uuid: string;
};
export interface PartyPerson {
  firstName: string;
  id: string;
  email: string;
  lastName: string;
  type: string;
}

export type OcrDatesArray = {
  date: string;
  lineText: string;
  coordinates: {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
  };
};

export type EffectiveDateAiSuggestionType = {
  date: string;
  lineText: string;
};

export type EndDateAiSuggestionType = {
  date: string;
  lineText: string;
};

export type DateSuggestionsPopoverType = {
  date: string;
  lineText: string;
};

export type AiContractTypesType = {
  contractType: string;
  contractTypeId: string;
  lineText: string;
};

export type ContractTypePopOverType = {
  contractType: string;
  contractTypeId: string;
  lineText: string;
};

export type OcrNoticePeriod = {
  noticePeriod: string;
  noticePeriodType: string;
  lineText: string;
  translationKey: string;
  coordinates: {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
  };
};
export type AiNoticePeriod = {
  noticePeriod: string;
  noticePeriodType: string;
  lineText: string;
  // translationKey: string
  pageNumber: string;
  paragraph: string;
};

export type CounterPartySuggestionType = {
  counterPartyName: string;
  lineText: string;
  pageNumber: number;
  coordinates: {
    pageIndex: number;
    height: number;
    width: number;
    left: number;
    top: number;
  };
};

export type AiPartyRecord = {
  lineText: string;
  pageNumber: string;
  paragraph: string;
  partyAddress: string;
  partyEmail: string;
  partyIdentificationNumber: string;
  partyName: string;
  partyPhone: string;
  partyType: string;
  personFirstName: string;
  personLastName: string;
  // ownParty: string;
};

export type PrivatePersonPartySuggestionType = {
  lineText: string;
  pageNumber: string;
  paragraph: string;
  partyAddress: string;
  partyEmail: string;
  partyIdentificationNumber: string;
  partyName: string;
  partyPhone: string;
  partyType: string;
  personFirstName: string;
  personLastName: string;
};

export type OrganizationsPartySuggestionType = {
  lineText: string;
  pageNumber: string;
  paragraph: string;
  partyAddress: string;
  partyEmail: string;
  partyIdentificationNumber: string;
  partyName: string;
  partyPhone: string;
  partyType: string;
  personFirstName: string;
  personLastName: string;
};

export type AiStatusType = {
  message: string;
  type: string;
};

export interface ContractFormData {
  title: string;
  contractOwner: number | string;
  tags: string[];
  description: string;
  effectiveDate: Date | null;
  endDate: Date | null;
  terms: string;
  noticePeriod: string | null;
  noticePeriodType: string | null;
  parties: PartiesPayload[];
  contractOwnerName?: string;
  contractOwnerEmail?: string;
  contractType: string;
  customField: string;
  customFieldData: any;
  contractStatus?: string;
  ocrDates: OcrDatesArray[];
  effectiveDateAiSuggestion: EffectiveDateAiSuggestionType[];
  endDateAiSuggestion: EndDateAiSuggestionType[];
  storageLocationUrl: string | null;
  location: any;
  ocrNoticePeriods: OcrNoticePeriod[];
  aiNoticePeriods: AiNoticePeriod[];
  aiParties: AiPartyRecord[];
  isSchemaEmpty: boolean;
  untilFurtherNotice: boolean;
  fileOCRStatus: string;
  ocrCounterParties: CounterPartySuggestionType[];

  typedTag: string;
  typedTagError: boolean;
  noEndDate: boolean;
  emailReminderDate: Date | null;
  notificationToDecesion: number | null;
  ocrOrganizations: Ocrorg[];
  ocrContractTerms: ContractTerm[];
  aiContractTerms: AiContractTerm[];
  aiContractTypes: AiContractTypesType[];
  aiPartyPersons: PrivatePersonPartySuggestionType[];
  aiPartyOrganizations: OrganizationsPartySuggestionType[];
  aiStatus: AiStatusType | null;
  aiContactPersons: AiContactPersons[];
  aiSignatoryPersons: AiSignatoryPersons[];
  ownPartyError?: boolean;
  contractPeriods?: ContractPeriods[];
  newPeriodStartDate?: Date | null;
  newPeriodEndDate?: Date | null;
  selectedOption?: string;
  suggestedContractPeriod?: {
    endDate: string,
    startDate: string
  }
}

export interface FormValues {
  effectiveDate: Date | null;
  endDate: Date | null;
  terms: string;
  noticePeriod: string | null;
  noticePeriodType: string | null;
  noEndDate: boolean;
  untilFurtherNotice: boolean;
  emailReminderDate: Date | null;
  notificationToDecesion: number | null;
  contractStatus?: string;
  newPeriodStartDate?: Date | null;
  newPeriodEndDate?: Date | null;
  suggestedContractPeriod?: {
    endDate: string,
    startDate: string
  }
}

export interface Tag {
  name: string;
  id: string;
}

export interface ContractFile {
  type: string;
}

export type SuggestedPartyContact = {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationUuid: string;
  mobileNo: string;
  aiSuggested?: boolean;
  party?: {
    uuid: string;
  };
  uniqueKey: string;
};

export type ContractFileRecord = {
  fileName: string;
  id: number;
  status: string;
  type: string;
  updatedBy: string;
  createdBy: number;
  lastModifiedAt: string;
  createdAt: string;
  link: string;
  fileUuid: string;
  preSignedUrl: string;
};

export type IParty = {
  type: string;
  name: string;
  number: string;
  partyRole: {
    id: number;
  };
  uuid: string | null;
  contacts?: SuggestedPartyContact[];
  aiSuggested?: boolean;
  organizationUuid?: string | null;
  email?: string;
};
export type PartiesPayload = {
  party: IParty;
  contact: {
    uuid: string | null;
    firstName: string;
    lastName: string;
    email: string;
    mobileNo: string;
    aiSuggested?: boolean;
    uniqueKey: string;
  };
  owner?: {
    firstName: string;
    lastName: string;
    email: string;
    uuid: string | null;
    mobile?: string;
    uniqueKey: string;
    id: string;
    name?: string;
  };
};

export interface CounterPartyObject {
  partyPerson?: {
    id: string;
  };
  partyOrganization?: {
    id: string;
    contact?: {
      id: number;
    } | null;
  } | null;
  id?: number;
}

type PayloadTag = {
  name: string;
};

export type ContractPeriods = {
  startDate: Date | null;
  endDate: Date | null;
};

export interface ContractPayload {
  contractOwner: null | string | number;
  contractDetail: {
    title: string;
    effectiveDate: string | null;
    endDate: string | null;
    noticePeriod: string | null;
    noticePeriodType: string | null;
    tags: PayloadTag[];
    description: string;
    jsonData: string | null;
    storageLocation?: {
      locationName: string;
    } | null;
    storageLocationUrl: string | null;
    untilFurtherNotice: boolean;
    emailReminderDate: Date | null;
    terms: string | null;
  };
  contractParties: PartiesPayload[];
  contractType?: {
    id: string;
  } | null;
  customField?: {
    id: string;
  };
  folder?: {
    uuid: string | null;
  };
  contractPeriods?: ContractPeriods[];
}

export interface Item {
  title: string;
  name: string;
  number: string;
  type: number;
  contact?: {
    name: string;
    number: string;
    email?: string;
    description: string;
  };
  sortOrder?: number;
  party?: {
    name: string;
  };
}
export type ContractData = {
  contractType: string;
  contractTypeTranslationKey: string;
  effectiveDate: string;
  contractOwner: string;
  contractOwnerUuid: string;
  contractOwnerId: string;
  noticePeriod: string;
  status: string;
  endDate: string;
  reviewed: string;
  description: string;
  tags: Tag[];
  contractParties: Item[];
  contractTypeId: string;
  jsonData: any;
  period: string;
  noticePeriodType: string;
  contractId?: string;
  expired: boolean;
  storageLocationName: string;
  storageLocationUrl: string;
  terms: string;
  terminationDeadlineDate: string;
  emailReminderDate: string;
  untilFurtherNotice: boolean;
  contractPeriods?: ContractPeriods[];
  suggestedContractPeriod?: {
    endDate: string,
    startDate: string
  }
};

export interface Party {
  id: number;

  party: {
    contact: string;
    id: number;
    name: string;
    number: string;
    type: string;
    partyRole: {
      type: string;
      id: number;
    };
  };
  partyPerson: {
    email: string;
    firstName: string;
    id: number;
    identificationNumber: string;
    lastName: string;
    mobileNo: string;
  };
  contact: any;
}

export interface TabRecord {
  name: string;
  value: string;
}

export type ContractType = {
  id: number;
  type: string;
  translationKey: string;
  isSchemaEmpty: boolean;
};

export type ContractTypeData = {
  id: number;
  type: string;
  translationKey: string;
  contractTypes: ContractType[];
};

export type AiContactPersons = {
  partyName: string;
  personEmail: string;
  personFirstName: string;
  personLastName: string;
  personPhone: string;
  lineText: string;
  pageNumber: number;
  paragraph: string;
};

export type AiSignatoryPersons = {
  partyName: string;
  personEmail: string;
  personFirstName: string;
  personLastName: string;
  personPhone: string;
  lineText: string;
  pageNumber: number;
  paragraph: string;
};

export interface Contact {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationUuid: string;
  mobileNo: string;
  uniqueKey: string;
}

interface PartyRole {
  id: number;
  type: string;
  status: string;
}

export interface ISuggestedParty {
  id: number;
  uuid: string;
  name: string;
  number: string;
  type: 'ORGANIZATION' | 'PRIVATE';
  organizationUuid: string;
  contacts: Contact[];
  partyRole: PartyRole;
  status: null | string;
  aliases?: IPartyAlias[]
}


export type UserInstance = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  role: string;
  mobile: string;
  uuid?: string;
};

export const PRIVATE_PERSON = 'Private person';
export const OWN_ORGANIZATION = 'Own organization';
export const ORGANIZATION = 'Organization';
export const PARTY_TYPE_PRIVATE = 'PRIVATE';
export const PARTY_TYPE_ORGANIZATION = 'ORGANIZATION';
