import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import { ICreateFolder } from "../../@types/inbox";
import { ILanguagesType } from "../../@types/language";

export const languageApi = createApi({
    reducerPath: "API/LANGUAGE",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['language'],
    endpoints(builder) {
        return {
            fetchLanguageList: builder.query<ILanguagesType[], void>({
                query() {
                    let endpointURL = `/us-api/languages/list`;

                    return {
                        url: endpointURL,
                    };
                },
                transformResponse: (response: Record<string, any>) => response.content,
                providesTags: ["language"]
            })
        };
    }
})

export const { useFetchLanguageListQuery } = languageApi