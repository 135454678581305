import useLocales from 'hooks/useLocales';
import Label from 'components/Label';

const WAITING_FOR_REVIEWED = 'WAITING_FOR_REVIEWED';
const NEW_REVIEWED_SUGGESTED = 'NEW_REVIEWED_SUGGESTED';
const NEW_REVIEWED_REQUIRED = 'NEW_REVIEWED_REQUIRED';
const TERMINATED = 'TERMINATED';
const DELETED = 'DELETED';
const REVIEWED = 'REVIEWED';
const DRAFT = 'DRAFT';
const ACTIVE = 'ACTIVE';
const IMPORTED = 'IMPORTED';
const PROCESSING = 'PROCESSING';
const ANALYSIS_FAILED = 'ANALYSIS_FAILED';

type Props = {
  contractStatus: string;
  filterByContactStatus?: () => void;
};


export default function ContractStatus(props: Props) {

  const { translate } = useLocales();
  const { contractStatus, filterByContactStatus } = props;
  let LabelComponent;

  const filterStatus = () => {
    if (filterByContactStatus) filterByContactStatus();
  };

  switch (contractStatus) {
    case PROCESSING:
      LabelComponent = (
        <Label
          color="info"
        >
          <span >
            {translate('contractRecordType.analyzing', 'Analyzing')}
          </span>
          <div className="loader"></div>
        </Label>
      );
      break;

    case WAITING_FOR_REVIEWED:
      LabelComponent = (
        <Label
          color="warning"
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.waitingForReview', 'Waiting for review')}
          </span>
        </Label>
      );
      break;

    case NEW_REVIEWED_SUGGESTED:
      LabelComponent = (
        <Label
          color='warning'
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.newReviewSuggested', 'New review suggested')}
          </span>
        </Label>
      );
      break;

    case NEW_REVIEWED_REQUIRED:
      LabelComponent = (
        <Label
          color='error'
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.newReviewRequired', 'New review required')}
          </span>
        </Label>
      );
      break;

    case TERMINATED:
      LabelComponent = (
        <Label
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.terminated', 'Terminated')}
          </span>
        </Label>

      );
      break;

    case DELETED:
      LabelComponent = (
        <Label
          color='warning'
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.deleted', 'Deleted')}
          </span>
        </Label>
      );
      break;

    case REVIEWED:
      LabelComponent = (
        <Label
          color='success'
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.reviewed', 'Reviewed')}
          </span>
        </Label>
      );
      break;

    case DRAFT:
      LabelComponent = (
        <Label
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.draft', 'Draft')}
          </span>
        </Label>
      );
      break;

    case ACTIVE:
      LabelComponent = (
        <Label
          color='success' onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.active', 'Active')}
          </span>
        </Label>
      );
      break;

    case IMPORTED:
      LabelComponent = (
        <Label
          onClick={filterStatus}
        >
          <span>
            {translate('contractStatus.imported', 'Imported')}
          </span>
        </Label>
      );
      break;
    case ANALYSIS_FAILED:
      LabelComponent = (
        <Label
          color="warning"
          onClick={filterStatus}
        >
          <span>
            {translate('contractRecordType.analysisFailed', 'Analysis failed')}
          </span>
        </Label>
      );
      break;

    default:
      break;
  }
  return (
    <span style={{ whiteSpace: 'nowrap', paddingTop: '.4rem', width: '100%' }}>{LabelComponent}</span>
  );
}
